import { Link } from 'react-router-dom';
import { Button } from '../../../app/components/Forms';
import { Trans } from 'react-i18next';
import { basePath } from '../routes';

type ActionProps = {
  onSave(event: React.MouseEvent<HTMLButtonElement>): void;
  disabled: boolean;
}

export const Actions = (props: ActionProps ) => (
  <>
    <Link to={basePath}><Trans i18nKey="cancel" /></Link>
    <Button variant="primary" label="balances.save" onClick={props.onSave} disabled={props.disabled} data-test-id="balance-action-save" />
  </>
);

