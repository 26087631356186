import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { findFieldFragments } from './actions';
import {selectFieldFragmentsList} from './customFieldsSlice';


export const FetchFieldFragmentListEffect = (): void => {
  const fieldFragmentsList = useAppSelector(selectFieldFragmentsList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if ( fieldFragmentsList === null) {
      dispatch(findFieldFragments());
    }
  }, [dispatch, fieldFragmentsList]);
};


