import { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { ToolBar } from '../../app/components/ToolBar';
import { ToolBarSearch } from '../../app/components/ToolBarSearch';
import { LinkButton } from '../../app/components/LinkButton';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getUsers } from './actions';
import { selectUsers } from './usersSlice';
import { ActionsDropdown } from './components/ActionsDropdown';

interface ActionsProps {
  onSearch: Function;
};
const Actions = (props: ActionsProps) => (
  <>
    <ToolBarSearch placeholder="users.search" onChange={props.onSearch} />
    <LinkButton to="/users/add" label="users.add" />
  </>
);

export function UsersOverview() {
  const users = useAppSelector(selectUsers);
  const dispatch = useAppDispatch();
  const [localUsers, setLocalUsers] = useState<User[]>([]);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  // Filter local users
  const filterUsers = useCallback((value:string) => {
    if (!users) { return; }
    if (value && value.length < 2) {
      return setLocalUsers(users);
    }
    setLocalUsers(users.filter((item) => `${item.firstname} ${item.lastname}`.toLowerCase().includes(value)));
  }, [users]);

  // Copy users for local use
  useEffect(() => {
    if (!users) { return; }
    setLocalUsers(users);
  }, [users]);

  return (<>
    <ToolBar title='users.title' actions={<Actions onSearch={filterUsers}/>} />
    <div className='o-table'>
      <table>
        <thead className='capitalize'>
          <tr>
            <td><Trans i18nKey="name" /></td>
            <td><Trans i18nKey="role" /></td>
            <td><Trans i18nKey="assigned shops" /></td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {localUsers.map(c =>
            <tr className='o-table-row' key={c.id}>
              <td><Link to={`/users/edit/${c.id}`}>{c.firstname} {c.lastname}</Link></td>
              <td className='capitalize'><Trans i18nKey={`user.role.${c.role}`} /></td>
              <td>{c.linkedShopsCount || '0'}</td>
              <td><ActionsDropdown user={c}/></td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </>
  )

}
