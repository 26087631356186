import * as React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from '../../app/hooks';
import { selectAuthenticated } from './authenticationSlice';

interface RequireAuthProps {
  children?: any;
  redirectTo?: string;
}

export const RequireAuth: React.FC<RequireAuthProps> = ({ children, redirectTo="/login" }: RequireAuthProps) =>  {
  let location = useLocation();
  const authenticated = useAppSelector(selectAuthenticated);
  if (authenticated) {
    return children;
  }

  return (
    <Navigate
      to={{
        pathname: redirectTo,
      }}
      state= {{
        from: {
          pathname: (location && location.pathname) || '',
        }
      }}
    />
  );
}
