import { createAsyncThunk } from '@reduxjs/toolkit';
import authenticationService from './authenticationService';
import { setAuthHeader, clearAuthHeader, getAuthHeader } from '../../api';
import { errorToast, successToast } from '../toast/toastSlice';

export const login = createAsyncThunk<any, UserCredentials, { rejectValue: string }>(
  '/login',
  async (payload:UserCredentials, { rejectWithValue, dispatch }) => {
    try {
      authenticationService().removeAccessToken();
      const result:any = await authenticationService().login(payload);
      if (result?.accessToken) {
        authenticationService().setAccessToken(result.accessToken);
        setAuthHeader(result.accessToken);
        dispatch(me());
      }
      return result;
    } catch (err:any) {
      console.log(err);
      const message = err && err.message ? err.message : 'nok';
      return rejectWithValue(message);
    }
  },
);

export const logout = createAsyncThunk<any, { cb?: Function }, { rejectValue: string }>(
  '/logout',
  async ({ cb }, { rejectWithValue }) => {
    try {
      authenticationService().removeAccessToken();
      const result = await authenticationService().logout();
      if (cb) {
        cb(result);
      }
      return result;
    } catch (err:any) {
      return rejectWithValue(err);
    }
  },
);

// The 'me' action will set the axios Authorization header to the accessToken in localStorage.
// If 'me' fails the axios Authorization header is removed and the login is shown
// If 'me' is valid the accessToken is re-used
export const me = createAsyncThunk<any, void, { rejectValue: string }>(
  '/me',
  async (_payload, { rejectWithValue }) => {
    const accessToken = authenticationService().getAccessToken();
    try {
      if (accessToken) {
        setAuthHeader(accessToken);
        const data = await authenticationService().me();
        return {
          user: data,
          accessToken,
        }
      } else {
        clearAuthHeader();
      }
      return rejectWithValue('');
    } catch (err:any) {
      if (accessToken) {
        authenticationService().removeAccessToken(accessToken);
        clearAuthHeader();
      }
      return rejectWithValue(err);
    }
  },
);

export const ping = createAsyncThunk<any, void, { rejectValue: AuthPingResultType }>(
  '/ping',
  async (_payload, { rejectWithValue }) => {
    const authHeader = getAuthHeader();
    try {
      if (authHeader) {
        await authenticationService().ping();
        return 'valid'; // Ping result is valid token
      }
      return rejectWithValue('no-authheader'); // Do not ping because there is not token
    } catch (err:any) {
      if (err && err.status && err.status === 401) {
        return rejectWithValue('invalid'); // Token is no longer valid
      }
      return rejectWithValue('unknown'); // Ping result is inconclusive (api down, no internet, ...)
    }
  },
);

export const updateUserPassword = createAsyncThunk<any, {id: string, payload: UpdateUserCredentials }, { rejectValue:
  string, dispatch: any }>(
  '/userPasswordUpdate',
  async ({ id, payload }, { rejectWithValue, dispatch }) => {
    try {
      const result = await authenticationService().updateUserPassword(id, payload);
      dispatch(successToast('api.updated'));
      return result;
    } catch (err:any) {
      if (err.message) {
        if (Array.isArray(err.message)) {
          err.message.map((c:string) => dispatch(errorToast(c)));
        } else if (typeof err.message === 'string') {
          dispatch(errorToast(err.message));
       }
      }
      console.log(err);
      return rejectWithValue(err);
    }
  },
);

export const updateUserProfile = createAsyncThunk<any, UserProfile , { rejectValue:
  string, dispatch: any }>(
  'profile/update',
  async (userProfile, { rejectWithValue, dispatch }) => {
    try {
      const result = await authenticationService().updateUserProfile(userProfile);
      return {user: result};
    } catch (err:any) {
      if (err.message) {
        if (Array.isArray(err.message)) {
          err.message.map((c:string) => dispatch(errorToast(c)));
        } else if (typeof err.message === 'string') {
          dispatch(errorToast(err.message));
       }
      }
      console.log(err);
      return rejectWithValue(err);
    }
  },
);
