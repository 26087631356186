import { createAsyncThunk } from '@reduxjs/toolkit';
import customFieldsService from './customFieldsService';
import { successToast } from '../toast/toastSlice';
 import { handleServiceError  } from '../../api/utils';
import cloneDeep from 'lodash/cloneDeep';
import { paging } from '../../api/utils';

export const findAll = createAsyncThunk<any, PageInfo|undefined, { rejectValue: string, dispatch: any }>(
  'customFields/findAll',
  async (pageInfo, { rejectWithValue, dispatch }) => {
    try {
      return await customFieldsService().findAll(paging(pageInfo));
    } catch (err: any) {
      console.log(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const findOne = createAsyncThunk<any, string, { rejectValue: string, dispatch: any }>(
  'customFields/findOne',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      return await customFieldsService().findOne({ id });
    } catch (err: any) {
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const create = createAsyncThunk<any, { customFields: PendingFieldDefinition, cb?: Function }, { rejectValue: string, dispatch: any }>(
  'customFields/create',
  async ({ customFields, cb }, { rejectWithValue, dispatch }) => {
    try {
      const result: any = await customFieldsService().create(customFields);
      if (result && result.id) {
        dispatch(successToast('api.custom-field.created'));
        if (cb) {
          cb(result);
        }
        return result;
      }
    } catch (err: any) {
      console.log(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const update = createAsyncThunk<any, { customFields: FieldDefinition, shops: Shop[], cb?: Function }, { rejectValue: string, dispatch: any }>(
  'customFields/update',
  async ({ customFields, shops, cb }, { rejectWithValue, dispatch }) => {
    try {
      const updateCustomfields = cloneDeep(customFields)
      // Current api updates user shops in seperate api call used below.
      updateCustomfields.shops = shops;
      const result: any = await customFieldsService().update(updateCustomfields);
      await customFieldsService().attachShops(customFields, shops);
      if (result && result.id) {
        dispatch(successToast('api.custom-field.updated'));
        if (cb) {
          cb(result);
        }
        return result;
      }
    } catch (err: any) {
      console.log(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const remove = createAsyncThunk<any, ObjectWithId, { rejectValue: string, dispatch: any }>(
  'customFields/remove',
  async (customFields, { rejectWithValue, dispatch }) => {
    try {
      await customFieldsService().remove(customFields);
      dispatch(successToast('api.custom-fields.removed'));
      return null;
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const attachShop = createAsyncThunk<any, { fieldDefinition: FieldDefinition, shops: Shop[] }, { rejectValue: string, dispatch: any }>(
  'customFields/attachShop',
  async ({ fieldDefinition, shops }, { rejectWithValue, dispatch }) => {
    try {
      return await customFieldsService().attachShops(fieldDefinition, shops);
    } catch (err: any) {
      console.log(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const findFieldFragments = createAsyncThunk<any, undefined, { rejectValue: string, dispatch: any }>(
  'customFields/findFieldFragments',
  async (_payloadCreator, { rejectWithValue, dispatch }) => {
    try {
       return await customFieldsService().findFieldFragments();
    } catch (err: any) {
      console.log(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

