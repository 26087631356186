import uniqueId from 'lodash/uniqueId';

export function defaultBalanceFieldOperation(balance:Balance):BalanceOperationBase {
  return {
    id: uniqueId('NEW'),
    balance: { id : balance.id },
    operationType: undefined,
    operationField: { id: null },
  };
}

export function defaultBalance():Balance {
  return {
    id: '',
    name: { en: '' },
    description: { en: ''},
    balanceFieldOperations: [],
    balanceFieldFragmentOperations: [],
    balanceNamedFieldOperations: [],
    balanceBalanceOperations: [],
  };
}

// Clone and clean BalanceOperationBase items so api POST and PATCH gets minimal required data
const balanceOperationReducer = (items: BalanceOperationBase[], balance:Balance) => {
  return items.reduce((acc, balanceOperationBase) => {
    if (!balanceOperationBase.operationField.id || !balanceOperationBase.operationType) {
      return acc;
    }
    //
    const balanceOperationBaseCopy = {...balanceOperationBase};
    if (balanceOperationBaseCopy?.id.indexOf('NEW') === 0) {
      delete balanceOperationBaseCopy.id;
    }
    acc.push({
      ...balanceOperationBaseCopy,
      operationField: { id : balanceOperationBaseCopy.operationField.id },
      balance: { id: balance.id || ''},
    });
    return acc;
  }, [] as BalanceOperationBase[]);
}

export function reduceBalance(localeBalance:Balance) {
  if (!localeBalance) {
    return;
  }
  return { balance: {
    ...localeBalance,
    balanceFieldOperations: balanceOperationReducer(localeBalance.balanceFieldOperations, localeBalance),
    balanceFieldFragmentOperations: balanceOperationReducer(localeBalance.balanceFieldFragmentOperations, localeBalance),
    balanceBalanceOperations: balanceOperationReducer(localeBalance.balanceBalanceOperations, localeBalance),
    balanceNamedFieldOperations: balanceOperationReducer(localeBalance.balanceNamedFieldOperations, localeBalance),
  }};
}


