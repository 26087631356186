import Axios, {AxiosRequestConfig, AxiosResponse, AxiosError} from 'axios';
import omit from 'lodash/omit';

export type HTTPMethod = 'get' | 'post' | 'put' | 'patch' | 'delete';

const timeOut = 60000;
const headers = {
  'Content-Type': 'application/json',
};
const verbose = false;

interface RestError {
  message?: string;
  status?: number;
}

export async function request<T>(url: string, method: HTTPMethod, data?: unknown): Promise<T> {
  const config: AxiosRequestConfig = {
    url: url,
    method: method,
    timeout: timeOut,
    headers: headers,
  };
  if (method.toUpperCase() === 'GET') {
    const params:any = data || {};
    config.params = {
      ...params,
      // disable browser cache for get requests
      ts: `${new Date().getTime()}`,
    };
  } else {
    config.data = typeof data === 'object' ? omit(data, ['updatedAt', 'createdAt', 'archivedAt']) : data;
  }
  return request_(config);
}

async function request_<T>(config: AxiosRequestConfig): Promise<T> {
  try {
    if (verbose) {
      interceptRequest(config);
    }
    const response = await Axios.request<T>(config);
    if (verbose) {
      interceptResponseSuccess(response);
    }
    return response.data;
  } catch (error: any) {
    return handleError(error);
  }
}

export async function upload(url: string, file: any, onUploadProgress:any): Promise<any> {
  const http = Axios.create();
  const formData = new FormData();
  formData.append("file", file);
  return http.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
}

export async function downloadExcel(url: string, data?: unknown): Promise<any> {
  const config: AxiosRequestConfig = {
    url: url,
    method: 'POST',
    timeout: timeOut,
    responseType: 'arraybuffer',
    headers: {
     'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    data,
  };

  return request_(config);
}


function interceptRequest(config: AxiosRequestConfig) {
  console.log('Request ' + config.method + ': ' + config.url);
  const data = config.params || config.data;
  if (data) {
    let requestString = JSON.stringify(data, null, 2);
    console.log(requestString);
  }
}

function interceptResponseSuccess(response: AxiosResponse) {
  console.log('Response Success ' + response.config.method + ': ' + response.config.url);
  if (response.data) {
    console.log(response.data);
  }
}

function handleError(error: any) {
  if (verbose) {
    interceptResponseFailure(error);
  }
  const err: RestError = {status: error?.response?.status, message: error?.response?.data?.message};
  return Promise.reject(err);
}

function interceptResponseFailure(error: AxiosError) {
  console.log('Response Failure ' + error?.config?.method + ': ' + error?.config?.url);
  if (error?.response?.data) {
    console.log(error?.response?.data);
  }
}

export function setAuthHeader(accessToken:string) {
  Axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
}

export function clearAuthHeader() {
  delete Axios.defaults.headers.common['Authorization'];
}

export function getAuthHeader() {
  return Axios.defaults.headers.common['Authorization'];
}

export function getResponseError(action:any):string {
  if (action?.payload) {
    if (action.payload.response?.data?.message) {
      return action.payload.response.data.message;
    }
    if (action.payload.response?.data?.error) {
      return action.payload.response.data.error;
    }
    if (action.payload.response?.statusText) {
      return action.payload.response.statusText;
    }
    if (action.payload?.message) {
      return action?.payload?.message;
    }
  }
  if (action?.error?.message) {
    return action?.error?.message;
  }
  return '';
}
