import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { updateUserProfile } from '../authentication/actions';
import { selectUserProfile } from '../authentication/authenticationSlice';
import { ToolBar } from '../../app/components/ToolBar';
import { PasswordChange } from './components/PasswordChange';
import {AvatarUpload} from './components/AvatarUpload';
import Language from '../../app/components/Language';

export const Edit: React.FC = () => {
  const profile = useAppSelector(selectUserProfile);
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const onLanguageChange = useCallback((value: keyof LanguageProperties) => {
    if (!profile) { return; }
    i18n.changeLanguage(value);
    dispatch(updateUserProfile({ ...profile, language: value }));
  }, [profile, dispatch, i18n]);

  return (
    <>
      <ToolBar title={<><Trans i18nKey="profile.edit" /> - {profile?.firstname} {profile?.lastname}</>} />
      <div className='flex space-x-2 justify-between'>
        <div className='bg-white p-8 w-full'>
          <AvatarUpload user={profile} />
          <Language user={profile} onChange={onLanguageChange} />
        </div>
        <div className='bg-white p-8 w-full'>
          <PasswordChange user={profile} />
        </div>
      </div>
    </>
  )
};
