export const fieldOperationType = [
  'add-field-start',
  'add-field-end',
  'subtract-field-start',
  'subtract-field-end',
  'ignore-field',
] as Array<BalanceFieldOperationType>;

export const fieldFragmentOperationType = [
  'add-field',
  'subtract-field',
  'ignore-field',
] as Array<BalanceFieldOperationType>;

export const namedFields = [
  { id: 'total', name: { en: 'Total' }}
] as Array<ObjectWithIdAndName>
