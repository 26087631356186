import { Link, useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectUserProfile } from '../../features/authentication/authenticationSlice';
import { Dropdown, Space, Avatar } from 'antd';
import type { MenuProps } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import { userAvatarUrl, userDisplayName } from '../../features/users/utils';
import { logout } from '../../features/authentication/actions';

export const ProfileDropdown = () => {
  const userProfile = useAppSelector(selectUserProfile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const items: MenuProps['items'] = [
    {
      label: <Link className='text-white' to='/profile'><Trans i18nKey="profile.edit" /></Link>,
      key: 'profile',
    },
    {
      type: 'divider',
    },
    {
      label: <Trans i18nKey="logout" />,
      key: 'logout',
    },
  ];

  if (!userProfile) { return null; }

  const onClick: MenuProps['onClick'] = async ({ key }) => {
    if (key === 'logout') {
      await dispatch(logout({}))
      navigate('/');
    }
  };

  return (
    <div className="flex items-center text-white">
      <Dropdown menu={{items, onClick}} trigger={['click']} placement="bottomRight">
        <a href="#none" onClick={e => e.preventDefault()}>
          <Space>
            <Avatar size={32} icon={<UserOutlined />} src={userAvatarUrl(userProfile)} />
            {userDisplayName(userProfile)}
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
    </div>
  );
};

export default ProfileDropdown;
