import {useState, useEffect } from "react";
import { userAvatarUrl, userDisplayName } from "../../users/utils";
import { useAppDispatch } from '../../../app/hooks';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import entriesService from '../entriesService';
import sortBy from "lodash/sortBy";

interface ProfileProps {
  shop: EntryShop | undefined;
  entry: Entry;
}

// Show profile of the user that made the first entry taking all versions into account.
// Show no profile if there is no entry.
export const Profile = (props: ProfileProps ) => {
  const dispatch = useAppDispatch();
  const [userProfile, setUserProfile] = useState<UserProfile|null>(null);

  // Fetch Entry with history
  useEffect(() => {
    if (userProfile) { return; }
    async function run() {
      if (!props.entry?.id) {
        setUserProfile(null);
        return;
      }
      const result:any = await entriesService().findOne({ id: props.entry.id });
      if (Array.isArray(result.entryValues) && result.entryValues.length > 0) {
        setUserProfile(sortBy(result.entryValues, 'version').shift().user);
      }
    }
    run();
  }, [dispatch, props.entry, userProfile]);

  // Clear userProfile if the entry changes
  useEffect(() => {
    setUserProfile(null);
  }, [props.entry]);

  return (
    <div className="flex flex-col p-4">
      <div className="text-center">
        {userProfile && <Avatar size={92} src={userAvatarUrl(userProfile)} /> }
        {!userProfile && <Avatar size={92} icon={<UserOutlined />} /> }
      </div>
      <div className="font-bold mt-2 text-center">{userDisplayName(userProfile)}</div>
    </div>
  );
}

