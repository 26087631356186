import React from 'react';
import { Trans } from 'react-i18next';
import { MoreOutlined, CopyOutlined } from '@ant-design/icons';
import { useAppDispatch } from '../../../app/hooks';
import {duplicate, findAll} from '../actions';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';

const items: MenuProps['items'] = [
  {
    label: <Trans i18nKey="duplicate" />,
    key: 'duplicate',
    icon: <CopyOutlined />
  },
];

interface Props {
  shop: Shop;
};
export const ActionsDropdown: React.FC<Props> = (props:Props) => {
  const dispatch = useAppDispatch();
  const handleMenuClick: MenuProps['onClick'] = evt => {
    if (evt.key === 'duplicate') {
      const run = async () => {
        await dispatch(duplicate(props.shop));
        dispatch(findAll());
      }
      run();
    }
    return false;
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Dropdown menu={menuProps} placement="bottomRight" trigger={['click']}>
      <button onClick={e => e.preventDefault()} className='text-base'>
        <MoreOutlined />
      </button>
    </Dropdown>
  );
}
