import * as React from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { LabeledTextField, Button } from '../../../app/components/Forms';
import { updateUserPassword, logout } from '../actions';
import { useAppDispatch } from '../../../app/hooks';

interface Props {
  userProfile: UserProfile | null,
  userCredentials: UserCredentials;
  disabled: boolean;
};

export const FirstLogin: React.FC<Props> = (props:Props) => {
  const dispatch = useAppDispatch();
  const [localeUserCredentials, setLocaleUserCredentials] = React.useState<UpdateUserCredentials>({
    oldPassword: '',
    password: '',
    passwordConfirmation: '',
  });
  const navigate = useNavigate();

  const onChange = React.useCallback((evt:any) => {
    const target = evt.target;
    setLocaleUserCredentials({...localeUserCredentials, [target.name]: target.value });
  }, [localeUserCredentials]);

  const onSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    if (!props.userProfile) { return; }
    dispatch(updateUserPassword({
      id: props.userProfile.id,
      payload: {
        ...localeUserCredentials,
        oldPassword: props.userCredentials.password,
      }
    }))
    .then(unwrapResult)
    .then(() => {
      // Clear input after successfull api update
      setLocaleUserCredentials({
        ...localeUserCredentials,
        oldPassword: '',
        password: '',
        passwordConfirmation: '',
      });
      navigate('/register');
    })
    .catch(() => {});
  }

  // If there was a refresh between login and this component
  if (!props.userCredentials.password) {
    dispatch(logout({}))
    .then(() => {
      navigate('/');
    })
  }

  if (!props.userProfile) { return null; }

  return (
    <div className='mb-8'>
      <h1><Trans i18nKey="login.first-login" /></h1>
      <form onSubmit={onSubmit}>
        <LabeledTextField name='password' type="password" label='password'
        defaultValue={localeUserCredentials.password} autoComplete="new-password" onChange={onChange}
        required={true} data-test-id="firstlogin-password" />
        <LabeledTextField name='passwordConfirmation' type="password" label='confirm password'
        defaultValue={localeUserCredentials.passwordConfirmation} autoComplete="new-password" onChange={onChange}
        required={true} data-test-id="firstlogin-confirm-password" />
        <Button variant="primary" label='update password' onClick={onSubmit} disabled={props.disabled} data-test-id="firstlogin-submit" />
      </form>
    </div>
  )

};

export default FirstLogin;
