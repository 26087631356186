import { createAsyncThunk } from '@reduxjs/toolkit';
import balancesService from './balancesService';
import { successToast } from '../toast/toastSlice';
 import { handleServiceError  } from '../../api/utils';
import { paging } from '../../api/utils';

export const findAll = createAsyncThunk<any, PageInfo|undefined, { rejectValue: string, dispatch: any }>(
  'balances/findAll',
  async (pageInfo, { rejectWithValue, dispatch }) => {
    try {
      return await balancesService().findAll(paging(pageInfo));
    } catch (err: any) {
      console.log(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const findOne = createAsyncThunk<any, string, { rejectValue: string, dispatch: any }>(
  'balances/findOne',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      return await balancesService().findOne({ id });
    } catch (err: any) {
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const create = createAsyncThunk<any, { balance: Balance }, { rejectValue: string, dispatch: any }>(
  'balances/create',
  async ({ balance }, { rejectWithValue, dispatch }) => {
    try {
      const result: any = await balancesService().create(balance);
      dispatch(successToast('api.balance.created'));
      return result;
    } catch (err: any) {
      console.log(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const update = createAsyncThunk<any, { balance: Balance }, { rejectValue: string, dispatch: any }>(
  'balances/update',
  async ({ balance }, { rejectWithValue, dispatch }) => {
    try {
      const result: any = await balancesService().update(balance);
      dispatch(successToast('api.balance.updated'));
      return result;
    } catch (err: any) {
      console.log(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const remove = createAsyncThunk<any, ObjectWithId, { rejectValue: string, dispatch: any }>(
  'balances/remove',
  async (balance, { rejectWithValue, dispatch }) => {
    try {
      await balancesService().remove(balance);
      dispatch(successToast('api.balances.removed'));
      return null;
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

