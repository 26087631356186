import { Route, Routes } from "react-router-dom";
import { Edit } from './Edit';

export const ProfileRoutes: React.FC = () =>  {
  return (
    <Routes>
      <Route path="/" element={<Edit />} />
    </Routes>
  );
}

