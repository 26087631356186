import { useCallback, useEffect, useMemo } from 'react';
import { Drawer, Button, DatePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import {Trans} from 'react-i18next';
import { useLocalStorageState, useBoolean } from 'ahooks';
import moment from 'moment';
import { useAppSelector } from '../../../app/hooks';
import { selectShops } from '../checksSlice';
import { SimpleTable } from '../../../app/components/SimpleTable';
import { asEntryDate } from '../../entries/utils';

const { RangePicker } = DatePicker;

interface Props {
  onClose: Function;
  onDownload: Function;
}

const disabledDate: RangePickerProps['disabledDate'] = current => {
  // Can not select days after today
  return current && current > moment().endOf('day');
};

export const DownloadSelector = (props: Props ) => {
  const {onDownload: _onDownload} = props;
  const shops = useAppSelector(selectShops);
  const [selectedShops, setSelectedShops] = useLocalStorageState('check-selected-shops', {
    defaultValue: [] as string[],
  });
  const [dateRange, setDateRange] = useLocalStorageState<[string,string]>('check-selected-date-range', {
    defaultValue: [asEntryDate(new Date()), asEntryDate(new Date())],
  });
  const [downloadable, { set: setDownloadable }] = useBoolean(false);

  const availableShops = useMemo(() => {
    if (shops === null) { return []; }
    return shops.map((item:Shop) => ({ key: item.id, id: item.id, name: item.name }));
  }, [shops]);

  const onDownload = useCallback(() => {
    if (downloadable && dateRange) {
      _onDownload(dateRange[0], dateRange[1], selectedShops);
    }
  }, [selectedShops, dateRange, _onDownload, downloadable]);

  const onUpdate = useCallback((selectedRowKeys:string[]) => {
    return setSelectedShops(selectedRowKeys);
  }, [setSelectedShops]);

  useEffect(() => {
    if (selectedShops && dateRange) {
      setDownloadable(selectedShops.length > 0 && dateRange[0].length > 0 && dateRange[1].length > 0);
    }
  }, [selectedShops, dateRange, setDownloadable])

  if (!dateRange) {
    return null;
  }

  return (
    <Drawer title={<Trans i18nKey='checks.download-with-date-range' />} size="large" placement="right" open={true} onClose={() => {props.onClose(); }}>
      <div className='grid grid-cols-1 content-between justify-items-start h-full'>
        <div className='flex flex-col gap-4'>
          <div>
            <h2 className='text-2xl font-bold mb-2'><Trans i18nKey="Date range" /></h2>
            <RangePicker
            onChange={(_dates, dateStrings) => setDateRange(dateStrings)}
            disabledDate={disabledDate}
            defaultValue={[moment(dateRange[0]), moment(dateRange[1])]}
            />
          </div>
          <div className='mb-4'>
            <SimpleTable
            title="shops"
            header="Shop"
            dataSource={availableShops}
            onSelectChange={onUpdate}
            placeholder="Select a shop"
            defaultValue={selectedShops}
            />
          </div>

        </div>
        <Button type="primary" onClick={onDownload} disabled={!downloadable}><Trans i18nKey="Download" /></Button>
      </div>
    </Drawer>
  );
}

