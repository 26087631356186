import { createAsyncThunk } from '@reduxjs/toolkit';
import { service } from './namedFieldsService';
 import { handleServiceError  } from '../../api/utils';

export const findAll = createAsyncThunk<any, undefined, { rejectValue: string, dispatch: any }>(
  'namedFields/findAll',
  async (_payloadCreator, { rejectWithValue, dispatch }) => {
    try {
      return await service().findAll();
    } catch (err: any) {
      console.log(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

