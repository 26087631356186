import * as React from 'react';
import {Trans} from 'react-i18next';
import { LabeledTextField, LabeledCheckbox } from '../../../app/components/Forms';
import { allRoles } from '../constants';
import Language from '../../../app/components/Language';

interface FormProps {
  onChange: Function;
  onSubmit: Function;
  user: User | PendingUser | UserProfile | null | undefined;
  actions?: React.ReactNode;
  children?: React.ReactNode;
};

interface RoleProps {
  onChange: React.FormEventHandler;
  value: string | undefined;
}

const Roles = (props: RoleProps) => (
  <div className='mb-4'>
    <h2 className='text-2xl font-bold mb-2'><Trans i18nKey="user.roles" /></h2>
    <div className='grid grid-cols-1'>
      {allRoles.map((c) => <LabeledCheckbox key={c} name="role" value={c} checked={props.value === c} label={`user.role.${c}`}
        onChange={props.onChange} />)}
    </div>
  </div>
)

export const UserForm: React.FC<FormProps> = (props: FormProps) => {
  const { user } = props;
  const onSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    props.onSubmit(null);
  }

  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    props.onChange(event.currentTarget.name, event.currentTarget.value);
  }

  if (!user) {
    return null;
  }

  return (
    <div className='o-box bg-white p-8 w-full'>
      <form onSubmit={onSubmit}>

        <div className="flex space-x-4">
          <LabeledTextField name='firstname' label='first name' defaultValue={user.firstname} onChange={onChange}
          required={true} />
          <LabeledTextField name='lastname' label='last name' defaultValue={user.lastname} onChange={onChange}
          required={true}/>
        </div>
        <div className="flex space-x-4">
          <LabeledTextField label='username' disabled={true} value={user.username} />
        </div>
        <div className="mb-8">
          <Language user={user} onChange={(value:string) => {props.onChange('language', value)}} />
        </div>

        <Roles onChange={onChange} value={user.role} />
        {props.actions ? (<div className='ml-auto flex items-center gap-x-4'>{props.actions}</div>) : null }


      </form>
      {props.children}
    </div>
  )
};

export default UserForm;
