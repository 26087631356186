import {useState, useEffect} from 'react';
import difference from 'lodash/difference';
import { Trans, useTranslation } from 'react-i18next';
import { findAll } from '../../features/custom-fields/actions';
import { selectCustomFields } from '../../features/custom-fields/customFieldsSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { LabeledCheckbox } from './Forms';
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';

interface ItemVisibilityProps {
  onChange: Function;
  fieldFragment: FieldFragment;
  visibility: VisibilityType;
}

const ItemVisibility: React.FC<ItemVisibilityProps> = (props: ItemVisibilityProps) => {
 const { t } = useTranslation();
 const checked=props?.visibility === 'visible';
 const useButtons = true;
 if (useButtons) {
    const onChange = ({ target: { value } }: RadioChangeEvent) => {
      props.onChange(props.fieldFragment, value);
    };
     const options = [
      { label: t('visbile'), value: 'visible' },
      { label: t('hidden'), value: 'hidden' },
      { label: `By Custom Field (${t(props.fieldFragment.visibility)})`, value: 'inherit' },
    ] as {label:string,value:VisibilityType}[];
    return (
      <div className='flex flex-col pb-2 capitalize'>
        <span className='pb-2'>{props.fieldFragment.slug}</span>
        <Radio.Group  options={options} onChange={onChange} value={props.visibility} optionType="button" buttonStyle="solid" />
      </div>
    );
 }
  return (
    <div>
      <LabeledCheckbox
        value={props.fieldFragment.id}
        checked={checked}
        onChange={() => { props.onChange(props.fieldFragment, checked ? 'hidden' : 'visible') }}
        label={props.fieldFragment.name.en}>
          <span className='text-gray-400'>
          (<Trans i18nKey={'custom-fields.custom-field'} /> setting is &quot;<Trans i18nKey={props.fieldFragment.visibility} />&quot;)
          </span>
      </LabeledCheckbox>
    </div>
  );
}

interface ItemProps {
  id: string;
  name: string; // FieldDefinition name (en)
  active: boolean;  // FieldDefinition active
  checked: boolean;
  onChange: React.FormEventHandler;
  onVisibilityChange: Function;
  disabled: boolean;
  fieldFragments?: FieldFragment[];
  fieldFragmentVisibilityMap: Map<string, VisibilityType>;
}

const Item: React.FC<ItemProps> = (props: ItemProps) => {
  return (
  <div>
    <LabeledCheckbox
      value={props.id} checked={props.checked} onChange={props.onChange} disabled={props.disabled}
      label={props.name}>
      {props.active === false && (
        <span className='text-gray-400 font-semi-bold'><Trans i18nKey={'inactive'} /></span>
      )}
    </LabeledCheckbox>
    {/** Override FieldFragment visibility set in 'Custom fields' view */}
    {props.checked && (
      <div className='ml-2 mb-4'>
        {props.fieldFragments && props.fieldFragments.map((item) => (
          <ItemVisibility key={item.id}
          visibility={props.fieldFragmentVisibilityMap.get(item.id) || 'inherit'}
          fieldFragment={item}
          onChange={props.onVisibilityChange}
          />
        ))}
    </div>
    )}
</div>
);
}

interface ListProps {
  title: React.ReactNode;
  onChange: Function;
  onVisibilityChange: Function;
  fields: FieldDefinition[] | null;
  fieldFragmentVisibilityMap: Map<string, VisibilityType>;
  checked: boolean;
  disabled: boolean;
  empty?: React.ReactNode;
}

const List = (props: ListProps) => (
  <div className='mb-4'>
    <h2 className='text-2xl font-bold mb-2'>{props.title}</h2>
    <div className='divide-y grid grid-cols-1'>
      {props.fields?.map((c:FieldDefinition) => <Item key={c.id} name={c.name.en} checked={props.checked}
        id={c.id}
        active={c.active}
        onChange={() => { props.onChange(c, !props.checked) }}
        onVisibilityChange={props.onVisibilityChange}
        disabled={props.disabled}
        fieldFragments={c.fieldFragments}
        fieldFragmentVisibilityMap={props.fieldFragmentVisibilityMap}
        />)}
    </div>
    {props.fields && props.fields.length === 0 && props.empty}
  </div>
)

interface Props {
  assigendFields: FieldDefinition[] | null;
  fieldFragmentVisibilityMap: Map<string, VisibilityType>;
  onChange: Function;
  onVisibilityChange: Function;
  children?: React.ReactNode;
}

export const EditLinkedFields: React.FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const fields = useAppSelector(selectCustomFields);
  const [availableFields, setAvailableFields] = useState<FieldDefinition[]>([]);

  useEffect(() => {
    dispatch(findAll());
  }, [dispatch]);

  // Filter available fields
  useEffect(() => {
    if (!fields) { return; }
    if (props.assigendFields) {
      const availableFieldIds = difference(fields.map(c => c.id), props.assigendFields.map(c => c.id));
      setAvailableFields(fields.filter(c => availableFieldIds.includes(c.id)) );
    } else {
      setAvailableFields(fields);
    }
  }, [fields, props.assigendFields]);

  return (
    <div className='o-box bg-white m-8 w-full'>
      {/** ASSIGNED FIELDS */}
      <List
        fields={props.assigendFields}
        fieldFragmentVisibilityMap={props.fieldFragmentVisibilityMap}
        title={<><Trans i18nKey="Assigned activities" /><span className='text-base font-normal pl-2'>({props.assigendFields?.length || 0})</span></>}
        checked={true}
        onChange={props.onChange}
        onVisibilityChange={props.onVisibilityChange}
        disabled={!Array.isArray(props.assigendFields)}
        empty={<div><Trans i18nKey="no assigned fields" /></div>}
      />

      {/** AVAILABLE FIELDS */}
      <List
        fields={availableFields}
        fieldFragmentVisibilityMap={props.fieldFragmentVisibilityMap}
        title={<Trans i18nKey="Other activities" />}
        checked={false}
        onChange={props.onChange}
        onVisibilityChange={() => {}}
        disabled={!Array.isArray(props.assigendFields)}
        empty={<div><Trans i18nKey="no fields available" /></div>}
      />

      {props.children}
    </div>
  )
}
