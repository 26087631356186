import { useMemo } from 'react';
import { Button, DatePicker } from '../../../app/components/Forms';
import { asEntryDate, previousDay, nextDay } from '../../entries/utils';
import { Loading } from '../../../app/components/Loading';

type ActionProps = {
  disabled: boolean;
  onChangeDate: Function;
  onShowConfig: Function;
  onShowFilter: Function;
  onShowDownload: Function;
  hasFilters: boolean;
  onDownload: Function;
  entryDate: string;
}

export const Actions = (props: ActionProps ) => {
  const today = useMemo(() => asEntryDate(new Date()), []);

  const onChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangeDate(event.target.value);
  };

  const onPreviousDay = () => {
    props.onChangeDate(previousDay(new Date(props.entryDate)));
  }

  const onNextDay = () => {
    const newDate = nextDay(new Date(props.entryDate), new Date(today));
    if (newDate && asEntryDate(newDate) !== props.entryDate) {
      props.onChangeDate(newDate);
    }
  }

  return (
    <>
      {props.hasFilters && <Button label="checks.filter" onClick={() => { props.onShowFilter() }} />}
      <Button label="checks.display-settings" onClick={() => { props.onShowConfig() }} />
      {props.hasFilters && <Button label="checks.download-with-date-range" onClick={() => { props.onShowDownload() }} />}
      <div className="flex content-center">
        <Button label="nav.previousEntry" onClick={onPreviousDay} />
        <DatePicker onChange={onChangeDate}  max={today} value={props.entryDate} />
        <Button label="nav.nextEntry" onClick={onNextDay} />
      </div>
      <Button variant="primary" label="download" onClick={() => { props.onDownload() }} />
      {(props.disabled )&& <div className="absolute right-0"> <Loading/> </div>}
    </>
  );
}

