import * as React from 'react';
import {
  Route,
  Routes
} from "react-router-dom";
import { AddUser } from './Add';
import { EditUser } from './Edit';
import { UsersOverview } from './Overview';

export const UserRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/edit/:id" element={<EditUser />} />
      <Route path="/add" element={<AddUser />} />
      <Route path="/" element={ <UsersOverview /> } />
    </Routes>
  );
}
