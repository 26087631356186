import { FormEventHandler } from 'react';
import { Drawer, Button } from 'antd';
import {Trans} from 'react-i18next';
import { LabeledCheckbox } from '../../../app/components/Forms';
import { getName} from '../../entries/utils';

interface ItemProps {
  name: string,
  id: string,
  checked: boolean,
  onChange: FormEventHandler;
}

const Item = (props: ItemProps ) => (
  <LabeledCheckbox
  value={props.id}
  checked={props.checked}
  onChange={props.onChange}
  label={props.name}/>
);

interface FilterItem {
  key: string;
  name: LanguageProperties|string;
}

interface Props {
  items: FilterItem[];
  selected: string[];
  onClose: Function;
  onChange: Function;
  onReset: Function;
  title: string;
}

export const Filters = (props: Props ) => {
  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    props.onChange(event.currentTarget.value, event.currentTarget.checked);
  }
  return (
    <Drawer title={<Trans i18nKey='checks.filter' />} size="large" placement="right" open={true} onClose={() => {props.onClose(); }}>
      <div className='grid grid-cols-1 content-between justify-items-start h-full'>
        <div>
          <h3 className='mb-2'><Trans i18nKey={props.title} /></h3>
          {props.items.map((item) => <Item key={item.key} id={item.key} name={getName(item)}
          checked={props.selected.indexOf(item.key) !== -1} onChange={onChange} />)}
          {props.items.length === 0 && <div><Trans i18nKey="checks.filter.no-data"/></div>}
        </div>
        <Button type="link" onClick={() => {props.onReset();}}><Trans i18nKey="reset to default"  /></Button>
      </div>
    </Drawer>
  );
}

