import * as React from 'react';
import {
  Route,
  Routes
} from "react-router-dom";
import { Overview } from './Overview';

export const RegisterRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Overview />} />
    </Routes>
  );
}
