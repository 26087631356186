import * as React from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { LabeledTextField } from '../../../app/components/Forms';
import { updateUserPassword } from '../../authentication/actions';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { Button } from '../../../app/components/Forms';
import {selectIsWaiting} from '../../users/usersSlice';

interface FormProps {
  user: UserProfile | null,
};

export const PasswordChange: React.FC<FormProps> = (props:FormProps) => {
  const dispatch = useAppDispatch();
  const { user } = props;
  const [localeUserCredentials, setLocaleUserCredentials] = React.useState<UpdateUserCredentials>({
    oldPassword: '',
    password: '',
    passwordConfirmation: '',
  });

  const isWaiting = useAppSelector(selectIsWaiting);

  const onChange = React.useCallback((evt:any) => {
    const target = evt.target;
    setLocaleUserCredentials({...localeUserCredentials, [target.name]: target.value });
  }, [localeUserCredentials]);

  const onSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    if (!user) { return; }
    dispatch(updateUserPassword({
      id: user.id,
      payload: localeUserCredentials,
    }))
    .then(unwrapResult)
    .then(() => {
      // Clear input after successfull api update
      setLocaleUserCredentials({
        ...localeUserCredentials,
        oldPassword: '',
        password: '',
        passwordConfirmation: '',
      });
    })
    .catch(() => {});
  }
  if (!user) { return null; }

  return (
    <div className='o-box bg-white p-8 w-full'>
      <form onSubmit={onSubmit}>

        <LabeledTextField name='oldPassword' type="password" label='old password'
        defaultValue={localeUserCredentials.oldPassword} autoComplete="off" onChange={onChange}
        required={true} />

        <LabeledTextField name='password' type="password" label='password'
        defaultValue={localeUserCredentials.password} autoComplete="off" onChange={onChange}
        required={true} />

        <LabeledTextField name='passwordConfirmation' type="password" label='confirm password'
        defaultValue={localeUserCredentials.passwordConfirmation} autoComplete="off" onChange={onChange}
        required={true} />

        <Button variant="primary" label='update password' onClick={onSubmit} disabled={isWaiting} />
      </form>
    </div>
  )
};

export default PasswordChange;
