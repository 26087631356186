import { Route, Routes } from "react-router-dom";
import { Overview } from './Overview';
import { Overview as Archived } from './archived/Overview';
// import { Edit as Archive } from './archived/Edit';
import { AddShop } from './Add';
import { EditShop } from './Edit';

export const ShopRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/edit/:id" element={<EditShop />} />
      <Route path="/add" element={<AddShop />} />
      <Route path="/archived" element={<Archived />} />
      {/** <Route path="/archive/:id" element={<Archive />} /> */}
      <Route path="/" element={ <Overview /> } />
    </Routes>
  );
}
