import {useState, useRef, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Button, Input, Space, Table } from 'antd';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';

interface DataType {
  id: React.Key;
  name: string;
}

interface Props {
  dataSource: DataType[],
  title: string;
  header: string;
  actionName?: string;
  onChange?: Function;  // Called when action button is clicked
  onSelectChange?: Function; // Called when row selection is changed
  placeholder?: string;
  highlight?: boolean;
  defaultValue?: string[];
}

type DataIndex = keyof DataType;

// Show a table with a single column ('name' prop from dataSource) with checkboxes and search
export const SimpleTable: React.FC<Props> = (props: Props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(props.defaultValue || []);
  const { onSelectChange : _onSelectChange } = props;

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    if (props.onSelectChange) {
      props.onSelectChange(newSelectedRowKeys);
    }
  };

  useEffect(() => {
    if (_onSelectChange) {
      _onSelectChange(selectedRowKeys);
    }
  }, [selectedRowKeys, _onSelectChange]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };


  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as string}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={(evt) => {
            handleSearch(selectedKeys as string[], confirm, dataIndex);
            evt.preventDefault();
            return false;
          }}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys as string[], confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#9b0020' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      (props.highlight && searchedColumn === dataIndex) ? (
        <Highlighter
          highlightStyle={{ color: '#ffffff', backgroundColor: '#9b0020', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<DataType> = [
    {
      title: props.header,
      dataIndex: 'name',
      sorter: (a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        return 0;
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend','descend'],
      ...getColumnSearchProps('name'),
    },
  ];

  return (
    <div className='mb-4'>
      <h2 className='text-2xl font-bold mb-2'><Trans i18nKey={props.title} /></h2>
      <div style={{ marginBottom: 16 }}>
        <span style={{ marginLeft: 8 }}>
          {props.actionName && (
          <Button
            type="primary"
            className='bg-primary-dark'
            onClick={() => {
              props.onChange && props.onChange(selectedRowKeys);
              setSelectedRowKeys([]);
            }}
          >
          <Trans i18nKey={props.actionName}/>
          </Button>
          )}
          {hasSelected &&  <Trans i18nKey="table-selection-count" values={{count: selectedRowKeys.length}}/>}
          {(!hasSelected && props.placeholder) && <Trans i18nKey={props.placeholder} />}
        </span>
      </div>
      <Table
      rowSelection={rowSelection}
      columns={columns}
      dataSource={props.dataSource}
      pagination={false}
      size="middle"
      rowKey='id'
      showSorterTooltip={false}
      />
    </div>
  );
}

export default SimpleTable;
