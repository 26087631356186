import { downloadExcel, request } from '../../api';
import config from '../../config';

const baseUrl = `${config.baseUrl}/checks`;

const service = () => {
  return {
    findOnDay: ({ date }: { date: string }) => request(`${baseUrl}/date/${date}`, 'get'),
    exportDay: ({ date, filters }: { date: string, filters: TableFilters }) =>
      downloadExcel(`${baseUrl}/date/${date}/export`, { entryDate: date, ...filters }),
    exportDateRange: ({ startDate, endDate, filters }: { startDate: string, endDate:string, filters: TableFilters }) =>
      downloadExcel(`${baseUrl}/date/${startDate}/${endDate}/export`, { startDate: startDate, endDate: endDate, ...filters }),
  };
};

export default service;
