import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ToolBar } from '../../app/components/ToolBar';
import { findOne, update, archive } from './actions';
import { cloneDeep } from 'lodash';
import { Form } from './components/Form';
import { Button } from 'antd';
import { selectShop, selectIsWaiting } from './shopsSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { EditLinkedUsers } from '../../app/components/EditLinkedUsers';
import { EditLinkedFields } from '../../app/components/EditLinkedFields';
import { Nav } from './components/ToolBarNav';
import { Actions } from './components/ToolBarActions';
import { Trans } from 'react-i18next';
import { Popconfirm } from '../../app/components/PopConfirm';
import {useMap} from 'ahooks';

export function EditShop() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const shop = useAppSelector(selectShop);
  const isWaiting = useAppSelector(selectIsWaiting);
  const navigate = useNavigate();

  const [localeShop, setLocaleShop] = useState<Shop|null>(null);
  const [localeLinkedUsers, setLocaleLinkedUsers] = useState<User[]>([]);
  const [localeLinkedFields, setLocaleLinkedFields] = useState<FieldDefinition[]>([]);
  // Map of FieldFragmentVisibility.FieldFragment.id as key and FieldFragmentVisibility.visibility as value
  const [localeFieldFragmentVisibilityMap, { setAll: setAllLocaleFieldFragmentVisibilityMap, set: setLocaleFieldFragmentVisibility }] = useMap<string, VisibilityType>([]);

  // Fetch shop
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    dispatch(findOne(id));
  }, [dispatch, id]);

  // Copy Shop into local Shop for edit
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    // Only update locale Shop once after receiving data
    if (shop !== null && localeShop === null && id === `${shop.id}`) {
      setLocaleShop(cloneDeep(shop));
      setLocaleLinkedUsers(cloneDeep(shop.users || []));
      setLocaleLinkedFields(cloneDeep(shop.fieldDefinitions || []));
      setAllLocaleFieldFragmentVisibilityMap(cloneDeep(shop.fieldFragmentVisibility || [])
        .filter(item => typeof item.fieldFragment?.id !== 'undefined')
        .map(item => ([item.fieldFragment.id, item.visibility])));
    }
  }, [shop, localeShop, id, setAllLocaleFieldFragmentVisibilityMap]);

  const updateShop = useCallback((key: string, value: string) => {
    if (localeShop === null) { return; }
    setLocaleShop({ ...localeShop, [key]: value });
  }, [localeShop]);

  const linkUsers = useCallback((users: User[], linked: boolean) => {
    // Add user to linked users
    if (linked) {
      setLocaleLinkedUsers([...localeLinkedUsers, ...users])
    } else {
      // Remove user from linked users
      const newList = localeLinkedUsers.reduce((acc, curr) => {
        if (users.find(user => user.id === curr.id)) {
          return acc;
        }
        acc.push(curr);
        return acc;
      }, [] as User[]);
      setLocaleLinkedUsers(newList);
    }
  }, [localeLinkedUsers]);

  const linkField = useCallback((field: FieldDefinition, linked: boolean) => {
    // Add field to linked fields
    if (linked) {
      if (localeLinkedFields.find(curr => curr.id === field.id)) {
        return;
      }
      setLocaleLinkedFields([...localeLinkedFields, field])
    } else {
      // Remove field from linked fields
      setLocaleLinkedFields(localeLinkedFields.filter(curr => curr.id !== field.id));
    }
  }, [localeLinkedFields]);

  const fieldFragmentVisibilityChange = useCallback((fieldFragment: FieldFragment, visibility: VisibilityType) => {
    setLocaleFieldFragmentVisibility(fieldFragment.id, visibility);
  }, [setLocaleFieldFragmentVisibility]);

  const onSubmit = useCallback(() => {
    if (localeShop === null) { return; }
    const fieldFragmentVisibility = Array.from(localeFieldFragmentVisibilityMap.keys()).map(key => ({
      fieldFragment: {id: key},
      visibility: localeFieldFragmentVisibilityMap.get(key),
    })) as FieldFragmentVisibility[];

    dispatch(update({
      shop: {...localeShop},
      users: [...localeLinkedUsers],
      fields: [...localeLinkedFields],
      fieldFragmentVisibility,
    }));
  }, [dispatch, localeShop, localeLinkedUsers, localeLinkedFields, localeFieldFragmentVisibilityMap]);

  const archiveShop = useCallback(async () => {
    if (typeof id !== 'string') { return; }
    await dispatch(archive({ id }));
    navigate('/shops');
  }, [dispatch, id, navigate]);

  return (
    <>
      <ToolBar title='shops.edit' nav={<Nav />} actions={<Actions onSave={onSubmit} disabled={isWaiting} />} />
      <div className='flex space-x-2 justify-between'>
        <Form onSubmit={onSubmit} shop={localeShop} onChange={updateShop}
        actions={
          <Popconfirm title="shops.archive" onConfirm={archiveShop}>
            <Button type="primary"><Trans i18nKey="shops.archive" /></Button>
          </Popconfirm>
        }
        >
          <EditLinkedFields
          onChange={linkField}
          onVisibilityChange={fieldFragmentVisibilityChange}
          assigendFields={isWaiting ? null : localeLinkedFields}
          fieldFragmentVisibilityMap={localeFieldFragmentVisibilityMap}
          />
        </Form>
        <EditLinkedUsers onChange={linkUsers} assigendUsers={isWaiting ? null : localeLinkedUsers} />
      </div>
    </>
  )
}
