import * as React from 'react';
import { List } from 'antd';
import { selectAvailableCustomFields } from '../../custom-fields/customFieldsSlice';
import { useAppSelector } from '../../../app/hooks';
import { fieldOperationType } from '../constants';
import cloneDeep from 'lodash/cloneDeep';
import { defaultBalanceFieldOperation } from '../utils';
import {RenderItem} from './OperationRenderItem';
import sortBy from 'lodash/sortBy';

interface Props {
  onChange: Function;
  balance: Balance;
  header: string;
};

export const BalanceFieldOperations: React.FC<Props> = (props: Props) => {
  const fieldDefinitions = useAppSelector(selectAvailableCustomFields);
  const [localFields, setLocalFields] = React.useState<BalanceOperationBase[]|null>(null);
  const { onChange } = props;
  const operationFieldName: keyof Balance = 'balanceFieldOperations';

  React.useEffect(() => {
    if (localFields === null && Array.isArray(props.balance[operationFieldName])) {
      if (props.balance[operationFieldName].length === 0 ) {
        setLocalFields([defaultBalanceFieldOperation(props.balance)]);
      } else {
        // Sort by 'createdAt' for now
        setLocalFields(sortBy(cloneDeep(props.balance[operationFieldName]), 'createdAt'));
      }
    }
  }, [props.balance, localFields]);

  // CHANGE
  const localeOnChange = React.useCallback((balanceOperationBase:BalanceOperationBase, key:keyof BalanceOperationBase, value: string) => {
    if (localFields === null) { return; }
    setLocalFields(localFields.map(item => {
      if (item.id === balanceOperationBase.id) {
        item[key] = value;
      }
      return item;
    }));
    onChange(operationFieldName, localFields);
  }, [localFields, onChange]);

  // ADD
  const addItem = React.useCallback((evt: React.MouseEvent<HTMLAnchorElement>) => {
    evt.preventDefault();
    if (localFields === null) { return; }
    const newLocaleBalanceFieldOperations = [...localFields, defaultBalanceFieldOperation(props.balance)];
    setLocalFields(newLocaleBalanceFieldOperations);
    onChange(operationFieldName, newLocaleBalanceFieldOperations);
  }, [localFields, props.balance, onChange]);

  // REMOVE
  const removeItem = React.useCallback((evt: React.MouseEvent<HTMLAnchorElement>, value:BalanceOperationBase) => {
    evt.preventDefault();
    if (localFields === null) { return; }
    const newValue = localFields.filter(item => item.id !== value.id);
    if (newValue.length === 0) {
      newValue.push(defaultBalanceFieldOperation(props.balance));
      // All local BalanceOperations are removed, set to null so useEffect will to new value from parent component
      onChange(operationFieldName, newValue);
      // Set LocalFields to null useEffect can pick up the change to props.balance
      return setLocalFields(null);
    }
    onChange(operationFieldName, newValue);
    return setLocalFields(newValue);
  }, [localFields, props.balance, onChange]);

  if (localFields === null ) { return null; }

  return (
    <List
    header={props.header}
    dataSource={localFields}
    renderItem={(item, idx) => <RenderItem
      value={item}
      fieldOptions={fieldDefinitions}
      operationOptions={fieldOperationType}
      onRemove={removeItem}
      onAdd={addItem}
      onChange={localeOnChange}
      showAdd={idx === localFields.length -1}
      fieldPlaceholder="select-a-custom-field"
      />}
    />
  );
}
