import React, { useCallback } from 'react';
import { Avatar, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useAppDispatch } from '../../../app/hooks';
import { me } from '../../authentication/actions';
import { userAvatarUrl } from '../../users/utils';
import { setImage } from '../../users/actions';
import { Button } from '../../../app/components/Forms';

interface AvatarUploadProps {
  user: UserProfile | null,
};

export const AvatarUpload: React.FC<AvatarUploadProps> = (props:AvatarUploadProps) => {
  const dispatch = useAppDispatch();
  const customRequest = useCallback((config:any) => {
    if (!props.user) { return; }
    dispatch(setImage({
      user: { id: props.user.id },
      image: (config.file) || null,
      cb: () => {
        dispatch(me());
      },
    }));
  }, [dispatch, props.user]);

  if (!props.user ) { return null; }
  return (
    <ImgCrop rotationSlider>
      <Upload showUploadList={false} customRequest={customRequest}>
        <div className="flex flex-col gap-2">
          <Avatar src={userAvatarUrl(props.user)} size={256}/>
          <Button label="Select image" />
        </div>
      </Upload>
    </ImgCrop>
  );
};

