import { useCallback, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {cloneDeep} from 'lodash';
import { basePath } from './routes';
import { ToolBar } from '../../app/components/ToolBar';
import { Form } from './components/Form';
import { selectBalance, selectIsWaiting, selectErrorMessage } from './balancesSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Nav } from './components/ToolBarNav';
import { Actions } from './components/ToolBarActions';
import { findOne, update } from './actions';
import { findAll as findAllCustomFields } from '../custom-fields/actions';
import {reduceBalance} from './utils';
import {FetchAllBalancesEffect } from './effects';
import {FetchAllNamedFieldsEffect} from '../named-fields/effects';
import {FetchFieldFragmentListEffect} from '../custom-fields/effects';
import {Feedback} from '../../app/components/Feedback';

export function EditBalance() {
  const dispatch = useAppDispatch();
  const balance = useAppSelector(selectBalance);
  const isWaiting = useAppSelector(selectIsWaiting);
  const errorMessage = useAppSelector(selectErrorMessage);
  const navigate = useNavigate();
  const { id } = useParams();

  const [localeBalance, setLocaleBalance] = useState<Balance|null>(null);

  // Copy Balance into local Balance for edit
  useEffect(() => {
    setLocaleBalance(cloneDeep(balance));
  }, [balance]);

  useEffect(() => {
    if (typeof id !== 'string') { return; }
    // Fetch balance
    dispatch(findOne(id));
    // Fetch fieldDefinitions for BalanceOperations
    dispatch(findAllCustomFields());
  }, [dispatch, id]);

  // Make sure sub components have a list of all Balances.
  FetchAllBalancesEffect();
  // Make sure sub components have a list of all NamedFields.
  FetchAllNamedFieldsEffect();
  // Make sure sub components have a list of all FieldFragments
  FetchFieldFragmentListEffect();

  const updateBalance = useCallback((key: keyof Balance , value: string, language: keyof LanguageProperties = 'en') => {
    if (!localeBalance) { return }
    return setLocaleBalance(
      {...localeBalance, [key]: {...localeBalance[key] , [language]: value }}
    );
  }, [localeBalance]);

  const updateFieldOperations = useCallback((key: keyof Balance, value: BalanceOperationBase[]) => {
    if (!localeBalance) { return }
    return setLocaleBalance(
      {...localeBalance, [key]: value }
    );
  }, [localeBalance]);

  const onSubmit = useCallback(() => {
    if (!localeBalance) { return }
    const run = async() => {
      try {
        const data = reduceBalance(localeBalance);
        if (data) {
          const result = await dispatch(update(data));
          if ('error' in result) {
            return false;
          }
        }
        navigate(basePath);
      } catch (err) {}
    };
    run();
  }, [dispatch, navigate, localeBalance]);

  return (
    <>
      <ToolBar title='balances.edit' nav={<Nav />} actions={<Actions onSave={onSubmit} disabled={isWaiting} />} />
      <div className='flex space-x-2 justify-between'>
        {localeBalance && <Form
          onSubmit={onSubmit}
          balance={localeBalance}
          onChange={updateBalance}
          onOperationChange={updateFieldOperations}
          /> }
        <Feedback errorMessage={errorMessage} />
      </div>
    </>
  )
}
