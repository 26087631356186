import * as React from 'react';
import { Route, Routes } from "react-router-dom";
import { Overview } from './Overview';
import { AddCustomFields } from './Add';
import { EditCustomFields } from './Edit';

export const CustomFieldRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/edit/:id" element={<EditCustomFields />} />
      <Route path="/add" element={<AddCustomFields />} />
      <Route path="/" element={<Overview />} />
    </Routes>
  );
}
