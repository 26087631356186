import { createSlice } from '@reduxjs/toolkit';
import { getResponseError } from '../../api';
import { RootState } from '../../app/store';
import { findAll, findOne, create, update } from './actions';

interface balancesState {
  error: boolean | null;
  isWaiting: boolean;
  items: Balance[] | null;
  current: Balance | null;
  message: string;
}

const initialState: balancesState = {
  error: false,
  isWaiting: false,
  items: null,
  current: null,
  message: '',
}

function pending(state:any, message:string = '') {
  state.error = null;
  state.isWaiting = true;
  state.message = message;
}

function fulfilled(state:any, message:string = '') {
  state.error = false;
  state.isWaiting = false;
  state.message = message;
}

function rejected(state:any, message:string = '') {
  state.error = true;
  state.isWaiting = false;
  state.message = message;
}

export const balancesSlice = createSlice({
  name: 'balances',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder
    // GET ALL
    .addCase(findAll.pending, (state) => {
      pending(state);
    })
    .addCase(findAll.fulfilled, (state, action) => {
      fulfilled(state);
      state.items = action.payload.items;
      state.current = null;
    })
    .addCase(findAll.rejected, (state, action) => {
      rejected(state, getResponseError(action));
      state.items = null;
    })
    // GET SINGLE
    .addCase(findOne.pending, (state) => {
      pending(state);
      state.current = null;
    })
    .addCase(findOne.fulfilled, (state, action) => {
      fulfilled(state);
      state.current = action.payload;
    })
    .addCase(findOne.rejected, (state, action) => {
      rejected(state, getResponseError(action));
      state.current = null;
    })
    // CREATE
    .addCase(create.pending, (state) => {
      pending(state, 'Saving balance');
      state.current = null;
    })
    .addCase(create.fulfilled, (state, action) => {
      fulfilled(state, 'Balance saved')
      state.current = action.payload;
    })
    .addCase(create.rejected, (state, action) => {
      rejected(state, getResponseError(action));
      state.current = null;
    })
    // UPDATE
    .addCase(update.pending, (state) => {
      pending(state, 'Saving balance');
    })
    .addCase(update.fulfilled, (state, action) => {
      fulfilled(state, 'Balance saved');
      state.current = action.payload;
    })
    .addCase(update.rejected, (state, action) => {
      rejected(state, getResponseError(action));
      state.current = null;
    })
    .addDefaultCase(() => { }),
});

// SELECTORS
export const selectBalances = (state: RootState) => state.balances.items;
export const selectBalance = (state: RootState) => state.balances.current;
export const selectAvailableBalances = (state: RootState) => state.balances.items || [];

export const selectMessage = (state: RootState): string => state.balances.message;
export const selectErrorMessage = (state: RootState): string  => state.balances.error === true ? state.balances.message : '';
export const selectIsWaiting = (state: RootState): boolean => state.balances.isWaiting;

export default balancesSlice.reducer;
