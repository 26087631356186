import * as React from 'react';
import { Trans } from 'react-i18next';
import { LabeledTextField } from '../../../app/components/Forms';
import { Button } from '../../../app/components/Forms';

export interface FormProps {
  onChange: Function;
  onSubmit: Function;
  userCredentials: UserCredentials;
  disabled: boolean;
};

export const Form: React.FC<FormProps> = (props: FormProps) => {
  const onSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    if (props.disabled) { return; }
    props.onSubmit(null);
  }

  const onChange = (event: React.FormEvent<HTMLInputElement>) =>
    props.onChange(event.currentTarget.name, event.currentTarget.value);

  return (
    <div className='mb-8'>
      <h1><Trans i18nKey="login.title" /></h1>
      <form onSubmit={onSubmit}>
        <LabeledTextField name='username' label='username' defaultValue={props.userCredentials.username} onChange={onChange}
        required={true} data-test-id="auth-username" />
        <LabeledTextField type='password' name='password' label='password' defaultValue={props.userCredentials.password} onChange={onChange}
        required={true} data-test-id="auth-password"/>
        <Button type="submit" variant="primary" label="login" disabled={props.disabled} data-test-id="auth-submit"/>
      </form>
    </div>
  )
};

export default Form;
