import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUser } from './actions';
import UserForm from './components/UserForm';
import { ToolBar } from '../../app/components/ToolBar';
import { EditLinkedShops } from '../../app/components/EditLinkedShops';
import { Nav } from './components/ToolBarNav';
import { Actions } from './components/ToolBarActions';
import { selectErrorMessage, selectIsWaiting } from './usersSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {Feedback} from '../../app/components/Feedback';

export function AddUser() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [user, setUser] = useState<PendingUser>({
    firstname: '',
    lastname: '',
    username: '',
    password: '',
    passwordConfirmation: '',
    role: "USER",
    language: "en",
  });
  const [localeLinkedShops, setLocaleLinkedShops] = useState<Shop[]>([]);
  const isWaiting = useAppSelector(selectIsWaiting);
  const errorMessage = useAppSelector(selectErrorMessage);

  const updateUser = useCallback((key: string, value: string) =>
    setUser({ ...user, [key]: value })
  , [user]);

  const onSubmit = useCallback(async () => {
    const cb = () => {
      navigate('/users');
    };
    dispatch(createUser({ user: {...user, shops: [...localeLinkedShops] }, cb}));
  }, [dispatch, navigate, user, localeLinkedShops]);

  const linkShops = useCallback((shops: Shop[], linked: boolean) => {
    // Add shops to linked shops
    if (linked) {
      setLocaleLinkedShops([...localeLinkedShops, ...shops])
    } else {
      // Remove shops from linked shops
      const newList = localeLinkedShops.reduce((acc, curr) => {
        if (shops.find(shop => shop.id === curr.id)) {
          return acc;
        }
        acc.push(curr);
        return acc;
      }, [] as Shop[]);
      setLocaleLinkedShops(newList);
    }
  }, [localeLinkedShops]);

  return (
    <>
      <ToolBar title='users.add' nav={<Nav />} actions={<Actions onSave={onSubmit} disabled={isWaiting} />} />
      <div className='flex space-x-2 justify-between'>
        <UserForm onSubmit={onSubmit} onChange={updateUser} user={user}>
          <Feedback errorMessage={errorMessage} />
         </UserForm>
        {localeLinkedShops && <EditLinkedShops onChange={linkShops} assignedShops={localeLinkedShops} /> }
      </div>
    </>
  )
}
