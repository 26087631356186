import { request, upload } from '../../api';
import config from '../../config';

export const baseUrl = `${config.baseUrl}/users`;

const service = () => {
  return {
    findAll: (pageInfo: PageInfo) => request(`${baseUrl}`, 'get', pageInfo),
    getUser: ({ id }: { id: string }) => request(`${baseUrl}/${id}`, 'get'),
    create: (user: PendingUser) => request(`${baseUrl}`, 'post', user),
    update: (user: User) => request(`${baseUrl}/${user.id}`, 'patch', user),
    remove: (user: ObjectWithId) => request(`${baseUrl}/${user.id}`, 'delete', user),
    duplicate: (user: ObjectWithId) => request(`${baseUrl}/${user.id}/duplicate`, 'get'),
    reset: (user: ObjectWithId) => request(`${baseUrl}/${user.id}/reset`, 'get'),
    attachShops: (user: User, shops: Shop[]) => request(`${baseUrl}/${user.id}/shops`, 'post', {shops}),
    upload: (user:ObjectWithId, file:any, onUploadProgress:any) => upload(`${baseUrl}/${user.id}/avatar`, file, onUploadProgress),
    removeImage: (user:ObjectWithId) => request(`${baseUrl}/${user.id}/avatar`, 'delete'),
  };
};

export default service;
