import * as React from 'react';
import { LabeledTextField } from '../../../app/components/Forms';

export interface FormProps {
  onChange: Function;
  onSubmit: Function;
  shop: Shop | PendingShop | null | undefined;
  actions?: React.ReactNode;
  children?: React.ReactNode;
};

export const Form: React.FC<FormProps> = (props: FormProps) => {
  const { shop } = props;
  const onSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    props.onSubmit(null);
  }

  const onChange = (event: React.FormEvent<HTMLInputElement>) =>
    props.onChange(event.currentTarget.name, event.currentTarget.value);

  if (!shop) {
    return null;
  }

  return (
    <div className='o-box bg-white p-8 w-full'>
      <form onSubmit={onSubmit}>
        <div className="flex space-x-4">
          <LabeledTextField name='name' label='shop name' defaultValue={shop.name} onChange={onChange}
          required={true} data-test-id="shop-name" />
        </div>
        {props.actions ? (<div className='ml-auto flex items-center gap-x-4'>{props.actions}</div>) : null }
      </form>
      {props.children}
    </div>
  )
};

export default Form;
