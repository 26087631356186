import * as React from 'react';

interface Props {
  errorMessage: string;
};

export const Feedback: React.FC<Props> = (props:Props) => {
  if (props.errorMessage) {
    return (
    <div className="text-white px-6 py-4 border-0 rounded mb-4 bg-red-400">
         {props.errorMessage}
      </div>
    )
  }
  return null;
}
