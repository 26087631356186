import {uniqueId} from 'lodash';

export function defaultShop(): Shop {
  return {
    id: uniqueId(),
    name: '',
    users: [],
    fieldFragmentVisibility: [],
    fieldDefinitions: [],
  };
}
