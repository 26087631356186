import { request } from '../../api';
import config from '../../config';

const baseUrl = `${config.baseUrl}/namedfields`;

export const service = () => {
  return {
    findAll: () => request(`${baseUrl}`, 'get'),
  };
};

export default service;
