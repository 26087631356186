import { useTranslation } from 'react-i18next';

interface ToolBarSearchProps {
  placeholder?: string;
  onChange?: Function;
};

export const ToolBarSearch = (props: ToolBarSearchProps) => {
  const { t } = useTranslation();
  const placeholder=t(props.placeholder || 'search');

  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(event.currentTarget.value);
  }

  return (
    <input
      type={'text'}
      className='px-2 rounded h-full border border-secondary-light'
      onChange={onChange}
      placeholder={placeholder} />);
}
