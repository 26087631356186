import { createAsyncThunk } from '@reduxjs/toolkit';
import entriesService from './entriesService';
import { successToast } from '../toast/toastSlice';
import { handleServiceError  } from '../../api/utils';
import {RootState} from '../../app/store';
import {setShopUserLanguage } from './entriesSlice';


// Get Shop and FieldDefinitions
export const findShops = createAsyncThunk<any, undefined, { rejectValue: string, dispatch: any, getState: any }>(
  'entries/findShops',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
       const result = await entriesService().findShops() as EntryShop[];
       const userProfileLanguage:keyof LanguageProperties = (getState() as RootState).authentication.user?.language || 'en';
       setShopUserLanguage(result, userProfileLanguage);
       return result;
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const findOne = createAsyncThunk<any, { id: string, cb?: Function }, { rejectValue: string, dispatch: any }>(
  'entries/findOne',
  async ({ id, cb }, { rejectWithValue, dispatch }) => {
    try {
      const entryResult = await entriesService().findOne({ id });
      if (cb) {
        cb(entryResult);
      }
      return entryResult;
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const create = createAsyncThunk<any, { entry: Entry, cb?: Function }, { rejectValue: string, dispatch: any }>(
  'entries/create',
  async ({ entry, cb }, { rejectWithValue, dispatch }) => {
    try {
      const result: any = await entriesService().create(entry);
      if (result && result.id) {
        dispatch(successToast('api.entry.created'));
        if (cb) {
          cb(result);
        }
        return result;
      }
      return null;
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const createValues = createAsyncThunk<any, { entryValues: EntryValue[], entry: ObjectWithId, cb?: Function }, { rejectValue: string, dispatch: any }>(
  'entries/createValues',
  async ({ entryValues, entry, cb }, { rejectWithValue, dispatch }) => {
    try {
      const result: any = await entriesService().createValues(entryValues, entry );
      dispatch(successToast('api.entry.created'));
      if (cb) {
        cb(result);
      }
      return result;
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const findToday = createAsyncThunk<any, { shop: ObjectWithId, cb?: Function }, { rejectValue: string, dispatch: any }>(
  'entries/findToday',
  async ({ shop, cb }, { rejectWithValue, dispatch }) => {
    try {
      const result = await entriesService().findToday({ shop });
      if (cb) {
        cb(result);
      }
      return result;
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const findNavForShop = createAsyncThunk<any, { shop: ObjectWithId, entryDate: string, cb?: Function }, { rejectValue: string, dispatch: any }>(
  'entries/findNavForShop',
  async ({ shop, entryDate, cb }, { rejectWithValue, dispatch }) => {
    try {
      const result = await entriesService().findNavForShop({ shop, date: entryDate });
      if (cb) {
        cb(result);
      }
      return result;
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);


export const findShopOnDay = createAsyncThunk<any, { shop: ObjectWithId, date: string, cb?: Function }, { rejectValue: string, dispatch: any }>(
  'entries/findShopOnDay',
  async ({ shop, date, cb }, { rejectWithValue, dispatch }) => {
    try {
      const entryResult:any = await entriesService().findShopOnDay({ shop, date });
      if (entryResult.id) {
        entryResult.entryValues = await entriesService().findValues(entryResult);
      }
      if (cb) {
        cb(entryResult);
      }
      return entryResult;
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);


export const findStatus = createAsyncThunk<any, { shop: ObjectWithId, date: string, cb?: Function }, { rejectValue: string, dispatch: any }>(
  'entries/findStatus',
  async ({ shop, date, cb }, { rejectWithValue, dispatch }) => {
    try {
      const result = await entriesService().status({ shop, date });
      if (cb) {
        cb(result);
      }
      return result;
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);
