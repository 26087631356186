import { createAsyncThunk } from '@reduxjs/toolkit';
import checksService from './checksService';
import { handleServiceError  } from '../../api/utils';

export const findOnDay = createAsyncThunk<any, { date: string }, { rejectValue: string, dispatch: any }>(
  'checks/findOnDay',
  async ({ date }, { rejectWithValue, dispatch }) => {
    try {
      const entryResult:any = await checksService().findOnDay({ date });
      return entryResult;
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const exportDay = createAsyncThunk<any, { date: string, filters: TableFilters }, { rejectValue: string, dispatch: any }>(
  'checks/exportDay',
  async ({ date, filters }, { rejectWithValue, dispatch }) => {
    try {
      const result:any = await checksService().exportDay({ date, filters });
      return result;
    } catch (err: any) {
      console.error(err);
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);
