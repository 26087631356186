import { Trans, useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { languages } from '../../i18n';

interface Props {
  user: UserProfile | null,
  onChange: Function,
};

export const Language: React.FC<Props> = (props:Props) => {
  const {  t } = useTranslation();
  if (!props.user) { return null; }

  return (
    <div className='pt-8 w-full'>
      <label className='pr-2' htmlFor="select-user-language">
        <Trans i18nKey="profile.language" />
      </label>
      <Select
        id="select-user-language"
        defaultValue={props.user.language}
        style={{ width: 120 }}
        onChange={(value:keyof LanguageProperties) => { props.onChange(value) }}
        options={languages.map(item => ({
              value: item.value,
              label: t(item.label),
            }))
        }
      />
    </div>
  )
};

export default Language;
