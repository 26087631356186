import i18n from 'i18next';
import translationEN from './en/translation.json';
import translationNL from './nl/translation.json';
import translationFR from './fr/translation.json';
import { initReactI18next } from 'react-i18next';

export const languages:LanguageOption[] = [
  { value: 'en', label: 'EN' },
  { value: 'nl', label: 'NL' },
  { value: 'fr', label: 'FR' },
];

export const resources = {
  'en': {
    translation: translationEN,
  },
  'nl': {
    translation: translationNL,
  },
  'fr': {
    translation: translationFR,
  },
} as const;

i18n.use(initReactI18next)
.init({
  lng: 'en',
  resources,
  fallbackLng: 'en',
  debug: false,
  parseMissingKeyHandler: (key:string):string => {
    if (key.indexOf('api.') === 0) {
      return key.substring(4);
    }
    // console.log(`[i18n] Missing key "${key}"`);
    return key;
  },
});

export function changeLanguage(value:keyof LanguageProperties) {
  return i18n.changeLanguage(value);
}

export default i18n;
