import * as React from 'react';
import { Route, Routes } from "react-router-dom";
import { Overview } from './Overview';
import { AddBalance } from './Add';
import { EditBalance } from './Edit';

export const basePath = '/balances';
export const BalancesRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/edit/:id" element={<EditBalance />} />
      <Route path="/add" element={<AddBalance />} />
      <Route path="/" element={<Overview />} />
    </Routes>
  );
}
