import * as React from 'react';
import { Trans } from 'react-i18next';

export interface ToolBarProps {
  title: string | React.ReactNode; // string to translate or <Trans ... />
  actions?: React.ReactNode;
  nav?: React.ReactNode;
};

export const ToolBar: React.FC<ToolBarProps> = (props: ToolBarProps) => (
  <header className='c-toolbar'>
    <div className='container mx-auto pt-12 pb-6 flex items-center gap-x-4 relative'>
      {/** NAV BUTTONS */}
      {props.nav ? (<div className='flex items-center gap-x-4'>{props.nav}</div>) : null }
      {/** TITLE */}
      <h1 className='mb-0'>
        {typeof props.title === 'string' && <Trans i18nKey={props.title} />}
        {typeof props.title === 'object' && props.title}
      </h1>
      {/** ACTION BUTTONS */}
      {props.actions ? (<div className='ml-auto flex items-center gap-x-4'>{props.actions}</div>) : null }
    </div>
  </header>
)
