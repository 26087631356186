import { request } from '../../api';
import config from '../../config';

const baseUrl = `${config.baseUrl}/balances`;

const service = () => {
  return {
    findAll: (pageInfo: PageInfo) => request(`${baseUrl}`, 'get', pageInfo),
    findOne: (balance: ObjectWithId) => request(`${baseUrl}/${balance.id}`, 'get'),
    create: (balance: Balance) => request(`${baseUrl}`, 'post', balance),
    update: (balance: Balance) => request(`${baseUrl}/${balance.id}`, 'patch', balance),
    remove: (balance: ObjectWithId) => request(`${baseUrl}/${balance.id}`, 'delete'),
  };
};

export default service;
