import { createSlice } from '@reduxjs/toolkit';
// import { getProfiles } from './actions';
import { RootState } from '../../app/store';

interface UserProfile {
  email: string;
  imageUrl: string;
  name: string;
  key: string;
}

interface userProfileState {
  error: boolean | null,
  isWaiting: boolean,
  items: UserProfile[] | null,
}

const initialState: userProfileState = {
  error: false,
  isWaiting: false,
  items: null,
}

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {},
  //-// extraReducers: (builder) => builder
  //-//   .addCase(getProfiles.pending, (state) => {
  //-//     state.error = null;
  //-//     state.isWaiting = true;
  //-//     state.items = null;
  //-//   })
  //-//   .addCase(getProfiles.fulfilled, (state, action) => {
  //-//     state.error = false;
  //-//     state.isWaiting = false;
  //-//     state.items = action.payload.map((c:UserProfile, idx:number) => ({
  //-//       ...c,
  //-//       key: `${c.email}-${idx}`,
  //-//     }));
  //-//   })
  //-//   .addCase(getProfiles.rejected, (state) => {
  //-//     state.error = true;
  //-//     state.isWaiting = false;
  //-//     state.items = null;
  //-//   })
  //-//   .addDefaultCase(() => {}),
});


// SELECTORS
export const selectUserProfiles = (state: RootState) => state.userProfile.items;

export default userProfileSlice.reducer;
