import { createSlice } from '@reduxjs/toolkit';
import { getResponseError } from '../../api';
import { RootState } from '../../app/store';
import { findAll } from './actions';

interface namedFieldssState {
  items: NamedField[] | null;
}

const initialState: namedFieldssState = {
  items: null,
}

function pending(state:any, message:string = '') {
  state.error = null;
  state.isWaiting = true;
  state.message = message;
}

function fulfilled(state:any, message:string = '') {
  state.error = false;
  state.isWaiting = false;
  state.message = message;
}

function rejected(state:any, message:string = '') {
  state.error = true;
  state.isWaiting = false;
  state.message = message;
}

export const namedFieldssSlice = createSlice({
  name: 'namedFields',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder
    // FIND ALL
    .addCase(findAll.pending, (state) => {
      pending(state);
    })
    .addCase(findAll.fulfilled, (state, action) => {
      fulfilled(state);
      state.items = action.payload.items;
    })
    .addCase(findAll.rejected, (state, action) => {
      rejected(state, getResponseError(action));
      state.items = initialState.items;
    })
    // DEFAULT
    .addDefaultCase(() => { }),
});

// SELECTORS
export const selectNamedFields = (state: RootState) => state.namedFields.items;

export default namedFieldssSlice.reducer;
