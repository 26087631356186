import * as React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

interface LinkButtonProps {
  label: string;
  to: string;
};

export const LinkButton: React.FC<LinkButtonProps> = (props: LinkButtonProps) => (
  <Link className="bg-primary-dark border p-2 px-4 mr-4 select-none text-white"
  to={props.to}><Trans i18nKey={props.label} /></Link>
);
