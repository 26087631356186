import config from '../../config';
import { request } from '../../api';

const baseUrl = `${config.baseUrl}/analytics`;

function sendToAnalytics(metric:any) {
  // const body = JSON.stringify(metric);
  // // Use `navigator.sendBeacon()` if available, falling back to `fetch()`
  // if (navigator.sendBeacon && false) {
  //   navigator.sendBeacon(baseUrl, body);
  // } else {
    request(`${baseUrl}`, 'post', metric);
  // }
}

const service = () => {
  return {
    send: sendToAnalytics,
  };
};

export default service;
