import * as React from 'react';
import { Input, Select, List, Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Trans } from 'react-i18next';

interface RenderItemProps {
  value: BalanceOperationBase;
  fieldOptions: ObjectWithIdAndName[];
  operationOptions: string[];
  onRemove: Function;
  onAdd: React.MouseEventHandler;
  showAdd: boolean;
  onChange: Function;
  fieldPlaceholder: string;
  operationPlaceholder?: string;
};

interface CheckAvailabilityProps {
  value: BalanceOperationBase;
}
export const CheckAvailability : React.FC<CheckAvailabilityProps> = (props: CheckAvailabilityProps) => {
  if (props?.value?.operationField ===  null) {
    return <div className='wrap bg-red-400 p-2 w-full'>Field <em>{props.value.id}</em> is no longer available</div>;
  }
  return null;
}

export const RenderItem: React.FC<RenderItemProps> = (props: RenderItemProps) => {
  if (props.value && props.value?.operationField === null) {
      return (
  <List.Item>
      <CheckAvailability value={props.value} />
      <span className="flex ml-4">
        { props.showAdd && <Button size='small' style={{marginRight: 4}} shape="circle" icon={<PlusOutlined />} onClick={props.onAdd} /> }
        <Button size='small' shape='circle' icon={<MinusOutlined />} onClick={(evt) => props.onRemove(evt, props.value) } />
      </span>
  </List.Item>
      );
  }
  return (
    <List.Item>
      <Input.Group>
        <Select
          allowClear={true}
          placeholder={<Trans i18nKey={props.fieldPlaceholder} />}
          defaultValue={props.value?.operationField?.id}
          options={props.fieldOptions.map(item => ({ label: item.name.en, value: item.id }))}
          onChange={value => props.onChange(props.value, 'operationField', { id: value })}
          dropdownMatchSelectWidth={false}
          className="w-64"
        />
        <Select
          allowClear={true}
          placeholder={<Trans i18nKey={props.operationPlaceholder || "Select an operation"} />}
          defaultValue={props.value.operationType}
          options={props.operationOptions.map(item => ({ label: <Trans i18nKey={item}/>, value: item })) }
          onChange={value => props.onChange(props.value, 'operationType', value)}
          dropdownMatchSelectWidth={false}
          className="w-80"
        />
      </Input.Group>
      <span className="flex ml-4">
        {props.showAdd && <Button size='small' style={{marginRight: 4}} shape="circle" icon={<PlusOutlined />} onClick={props.onAdd} /> }
        <Button size='small' shape='circle' icon={<MinusOutlined />} onClick={(evt) => props.onRemove(evt, props.value) } />
      </span>
    </List.Item>
  )
}

