import {useMemo, useState} from 'react';
import {uniqueId} from 'lodash';
import { Trans } from 'react-i18next';
import { Input, Select } from 'antd';
import { GB, NL, FR } from 'country-flag-icons/react/3x2';
import { FontColorsOutlined } from '@ant-design/icons';

const { Option } = Select;

const countryFlag = (value:keyof LanguageProperties) => {
  const style = {width:28};
  if (value === 'en') { return <GB style={style}/>; }
  if (value === 'nl') { return <NL style={style}/>; }
  if (value === 'fr') { return <FR style={style}/>; }
  return null;
}
// interface SelectAfterProps extends React.InputHTMLAttributes<HTMLInputElement> {
interface SelectAfterProps {
  defaultValue: keyof LanguageProperties;
  options: Array<keyof LanguageProperties>;
  values: LanguageProperties;
  onChange: Function;
};

const SelectAfter: React.FC<SelectAfterProps> = (props: SelectAfterProps) => {
  return (
    <Select
    defaultValue={props.defaultValue}
    className="select-after"
    dropdownMatchSelectWidth={false}
    optionLabelProp="label"
    defaultOpen={false}
    placement="bottomRight"
    onChange={value => props.onChange(value)}
    >
      {props.options.map(option =>
         <Option key={option} value={option} label={option}>
           <div className="flex gap-x-6 content-center">
             {countryFlag(option)}
             <span className='grow'><Trans i18nKey={option.toUpperCase()} /></span>
             <FontColorsOutlined
               className={(typeof props.values[option] === 'undefined' || props.values[option]?.length === 0) ? 'text-gray-400' : 'text-red-700' }/>
           </div>
         </Option>
      )}
    </Select>
  );
}

interface Props {
  label: string | React.ReactNode;
  values: LanguageProperties;
  name: string;
  onChange: Function;
  dataTestId?: string;
};

export const TextField: React.FC<Props> = (props: Props) => {
  const options = ['en', 'nl', 'fr'] as Array<keyof LanguageProperties>;

  const [selectedOption, setSelectOption] = useState<keyof LanguageProperties>('en');
  const localId = useMemo(() => { return uniqueId('labeled-text-field'); }, []);
  const defaultValue = useMemo(() => props.values[selectedOption], [props.values, selectedOption]);
  const onOptionChange = (value:keyof LanguageProperties) => setSelectOption(value);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    props.onChange(props.name, event.target.value, selectedOption);

  if (options.length === 0) { return null; }

  return (
    <div className='mb-4'>
      <label htmlFor={localId} className='pb-2'>
        {typeof props.label === 'string' && <Trans i18nKey={props.label} />}
        {typeof props.label !== 'string' && props.label}
      </label>
      <Input
      id={localId}
      name={props.name}
      addonAfter={<SelectAfter options={options} values={props.values} defaultValue={selectedOption} onChange={onOptionChange}/>}
      value={defaultValue}
      onChange={onInputChange}
      placeholder={selectedOption !== 'en' ? props.values.en : ''}
      data-test-id={props.dataTestId}
      />
    </div>
  )
};
