import { useEffect, useCallback, useState } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ToolBar } from '../../../app/components/ToolBar';
import { ToolBarSearch } from '../../../app/components/ToolBarSearch';
import {findArchived, unarchive} from '../actions';
import {selectArchived} from '../shopsSlice';
import { Popconfirm } from '../../../app/components/PopConfirm';

interface ActionsProps {
  onSearch: Function;
};
const Actions = (props: ActionsProps) => (
  <>
    <ToolBarSearch placeholder="shops.search" onChange={props.onSearch} />
  </>
);

export function Overview() {
  const shops = useAppSelector(selectArchived);
  const dispatch = useAppDispatch();
  const [localShops, setLocalShops] = useState<Shop[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(findArchived());
  }, [dispatch]);

  // Filter local shops
  const filterShops = useCallback((value:string) => {
    if (!shops) { return; }
    if (value && value.length < 2) {
      return setLocalShops(shops);
    }
    setLocalShops(shops.filter((item) => item.name.toLowerCase().includes(value)));
  }, [shops]);

  // Copy shops for local use
  useEffect(() => {
    if (!shops) { return; }
    setLocalShops(shops);
  }, [shops]);

  const archiveShop = useCallback(async (shop:Shop) => {
    await dispatch(unarchive(shop));
    navigate(`/shops/edit/${shop.id}`);
  }, [dispatch, navigate]);

  return (<>
    <ToolBar title='shops.archived' actions={<Actions onSearch={filterShops}/>} />
    <div className='o-table'>
      <table>
        <thead>
          <tr>
            <td><Trans i18nKey="name" /></td>
            <td><Trans i18nKey="assigned users" /></td>
            <td><Trans i18nKey="assigned activities" /></td>
            <td><Trans i18nKey="archived on date" /></td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {localShops.map(c =>
            <tr className='o-table-row' key={c.id}>
              <td>{c.name}</td>
              <td>{c.linkedUserCount || '0'}</td>
              <td>{c.linkedFieldCount || '0'}</td>
              <td>{`${new Date(c.deletedAt || '').toLocaleDateString()}`}</td>
              <td>
                <Popconfirm title="shops.unarchive" onConfirm={() => { archiveShop(c) }}>
                  <Button type="primary"><Trans i18nKey="shops.unarchive" /></Button>
                </Popconfirm>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </>
  )
}
