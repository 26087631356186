import { request } from '../../api';
import config from '../../config';

const baseUrl = `${config.baseUrl}/shops`;

const service = () => {
  return {
    findAll: (pageInfo: PageInfo) => request(`${baseUrl}`, 'get', pageInfo),
    findOne: ({ id }: { id: string }) => request(`${baseUrl}/${id}`, 'get'),
    create: (shop: PendingShop) => request(`${baseUrl}`, 'post', shop),
    update: (shop: Shop) => request(`${baseUrl}/${shop.id}`, 'patch', shop),
    remove: (shop: ObjectWithId) => request(`${baseUrl}/${shop.id}`, 'delete', shop),
    archive: (shop: ObjectWithId) => request(`${baseUrl}/${shop.id}/archive`, 'get'),
    unarchive: (shop: ObjectWithId) => request(`${baseUrl}/${shop.id}/unarchive`, 'get'),
    findArchived: () => request(`${baseUrl}/archived`, 'get'),
    duplicate: (shop: ObjectWithId) => request(`${baseUrl}/${shop.id}/duplicate`, 'get'),
    attachUsers: (shop: Shop, users: User[]) => request(`${baseUrl}/${shop.id}/users`, 'post', { users }),
    updateFields: (shop: Shop, fields: FieldDefinition[], fieldFragmentVisibility: FieldFragmentVisibility[]) =>
      request(`${baseUrl}/${shop.id}/fields`, 'post', {
        fieldDefinitions: fields,
        fieldFragmentVisibility,
      }),
  };
};

export default service;
