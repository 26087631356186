import * as React from 'react';
import { Radio } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { fieldUserRoles } from '../constants';
import { Trans } from 'react-i18next';

interface Props {
  onChange: Function;
  customField: FieldFragment;
};

export const UserRoles: React.FC<Props> = (props: Props) => {
  const { customField, onChange } = props;

  const localeOnChange = React.useCallback(({ target }: RadioChangeEvent) => {
    onChange(target.name, target.value, customField.id);
  }, [customField, onChange]);

  return (
    <>
      <div className='grid grid-cols-2 mb-2'>
        <label>
          <Trans i18nKey="view-user-role" />
        </label>
        <Radio.Group
          options={fieldUserRoles.map(curr => ({value: curr,  label: <Trans i18nKey={`user.role.${curr}`} />}) )}
          name="viewRole"
          onChange={localeOnChange}
          value={props.customField.viewRole}
          optionType="button"
        />
      </div>
      <div className='grid grid-cols-2 mb-2'>
        <label>
          <Trans i18nKey="edit-user-role" />
        </label>
        <Radio.Group
          options={fieldUserRoles.map(curr => ({value: curr,  label: <Trans i18nKey={`user.role.${curr}`} /> }) )}
          name="editRole"
          onChange={localeOnChange}
          value={props.customField.editRole}
          optionType="button"
          disabled={props.customField.viewRole === 'ADMIN'}
        />
      </div>
    </>
  );
}
