import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { selectFieldDefinitionSummary } from '../entriesSlice';
import {Trans} from 'react-i18next';
import { formatAmount } from '../utils';


interface ListProps {
  items: FieldDefinitionSummaryNew[] | null;
}

const List = (props: ListProps ) => {
  if (!props.items) { return null; }
  return (
    <div>
      {props.items && props.items.map(item => (
        <div key={item.key} className="flex justify-between text-base">
          <div>{item.name.en} <Trans i18nKey="custom-fields.summary-end" /></div>
          <div className='whitespace-nowrap'>{formatAmount(item.fieldDefinition_calc_endOfDay)} &euro;</div>
        </div>
      ))}
    </div>
  );
}

interface StatusProps {
  entry: Entry;
  entryDate: string;
  fieldDefinitions?: FieldDefinition[]; // only used to sort the summary
}

export const Status = (props: StatusProps ) => {
  const fieldDefinitionSummary = useAppSelector(selectFieldDefinitionSummary);
  const [cashStart, setCashStart] = useState<number|undefined>(undefined);
  const { fieldDefinitions } = props;

  // Sort fieldDefinitionSummary so its matches the order of the entries form
  const sortedFieldDefinitionSummary = useMemo(() => {
    if (Array.isArray(fieldDefinitions) && fieldDefinitionSummary) {
      const sortOrder = fieldDefinitions.map(item => item.id);
      return [...fieldDefinitionSummary].sort((a, b) =>
        sortOrder.indexOf(a.key) - sortOrder.indexOf(b.key)
      );
    }
    return fieldDefinitionSummary as FieldDefinitionSummaryNew[];
  }, [fieldDefinitionSummary, fieldDefinitions]);

  useEffect(() => {
    if (!fieldDefinitionSummary) {
      return;
    }
    const cashSummary = fieldDefinitionSummary.find(item => item.name.en.toLowerCase() === 'cash');
    setCashStart(cashSummary?.fieldDefinition_calc_startOfDay || 0 );
  }, [fieldDefinitionSummary])

  if (!props.entry || !fieldDefinitionSummary) {
    return null;
  }

  return (
    <div className='divide-y divide-gray-light  space-y-4'>
      <List items={sortedFieldDefinitionSummary} />
      <div className="flex justify-between text-base pt-4">
        <div><Trans i18nKey="cash-start" /></div>
        <div className="font-bold whitespace-nowrap">{formatAmount(cashStart)} &euro;</div>
      </div>
    </div>
  );
}
