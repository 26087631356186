import { request } from '../../api';
import config from '../../config';

const baseUrl = `${config.baseUrl}/entries`;

const service = () => {
  return {
    findOne: ({ id }: { id: string }) => request(`${baseUrl}/${id}`, 'get'),
    create: (entry: Entry) => request(`${baseUrl}`, 'post', entry),
    createValues: (entryValues: EntryValue[], entry: ObjectWithId) => request(`${baseUrl}/${entry.id}/values`, 'post', { entryValues }),
    update: (entry: Entry) => request(`${baseUrl}/${entry.id}`, 'patch', entry),
    findShops: () => request(`${baseUrl}/shops`, 'get'),
    findFields: ({ fieldDefinitions }: { fieldDefinitions: ObjectWithId[] }) => request(`${baseUrl}/fields`, 'post', { fieldDefinitions }),
    findValues: (entry:ObjectWithId ) => request(`${baseUrl}/${entry.id}/values`, 'get'),
    findToday: ({ shop }: { shop: ObjectWithId }) => request(`${baseUrl}/today/${shop.id}`, 'get'),
    findNavForShop: ({ shop, date }: { shop: ObjectWithId, date: string }) => request(`${baseUrl}/shop/${shop.id}/near/${date}`, 'get'),
    findShopOnDay: ({ shop, date }: { shop: ObjectWithId, date: string }) => request(`${baseUrl}/shop/${shop.id}/${date}`, 'get'),
    status: ({ shop, date }: { shop: ObjectWithId, date: string }) => request(`${baseUrl}/shop/${shop.id}/status/${date}`, 'get'),
  };
};

export default service;
