import { InputNumber, Input } from 'antd';
import { formatAmount, canViewFieldByRole, canEditFieldByRole } from '../utils';
import { inputNumberMinValue, inputNumberPrecision } from '../constants';

const { TextArea } = Input;

interface FieldFragmentProps {
  values?: Map<string, number>;
  textValues?: Map<string, string>;
  fieldFragment: FieldFragment;
  onChange: Function;
  canEdit: boolean;
  userRole: UserRole;
}

interface FieldFragmentNumberProps extends FieldFragmentProps{
  defaultValue: number;
  min?: number;
}

interface FieldFragmentTextProps extends FieldFragmentProps {
  defaultValue: string;
}

export const FieldFragmentNumber = (props: FieldFragmentNumberProps ) => (
  <div>
    <label>{props.fieldFragment.name.current}</label>
    {props.fieldFragment.description && <div className="text-gray-500 text-sm">{props.fieldFragment.description.current}</div>}
    {props.canEdit &&
    <InputNumber
      addonAfter="€"
      defaultValue={props.defaultValue || 0}
      precision={inputNumberPrecision}
      min={typeof props.min === 'number' ? props.min : inputNumberMinValue}
      onChange={(value) => props.onChange(props.fieldFragment, value)}
      onBlur={(evt) => {if(evt.target.value.trim() === '') { props.onChange(props.fieldFragment, 0)}}}
    />}
    {!props.canEdit &&
      <div className="flex gap-2">
        <span>{formatAmount(props.defaultValue)}</span>
        <span>€</span>
      </div>
    }
  </div>
);

export const FieldFragmentText = (props: FieldFragmentTextProps ) => (
  <div>
    <label>{props.fieldFragment.name.current}</label>
    {props.fieldFragment.description && <div className="text-gray-500 text-sm">{props.fieldFragment.description.current}</div>}
    {props.canEdit &&
    <TextArea
      defaultValue={props.defaultValue}
      onChange={(event) => props.onChange(props.fieldFragment, event.currentTarget.value)}
      showCount={true}
      rows={6}
      maxLength={512}
    />}
    {!props.canEdit &&
      <div>
        {props.defaultValue}
      </div>
    }
  </div>
);

export const FieldFragment = (props: FieldFragmentProps ) => {
  const canEdit = props.canEdit && canEditFieldByRole(props.userRole, props.fieldFragment);
  const canView = canViewFieldByRole(props.userRole, props.fieldFragment);
  if (!canView) {
    return null;
  }
  if (props.fieldFragment.inputType === 'number') {
    return <FieldFragmentNumber {...props} canEdit={canEdit} defaultValue={(props.values && props.values.get(props.fieldFragment.id)) || 0} />;
  } else if (props.fieldFragment.inputType === 'text') {
    return <FieldFragmentText {...props} canEdit={canEdit} defaultValue={(props.textValues && props.textValues.get(props.fieldFragment.id)) || ''} />;
  }
  return null;
}

interface FieldDefinitionProps {
  field: FieldDefinition;
  fieldFragmentVisibility: FieldFragmentVisibility[];
  values?: Map<string, number>;
  textValues?: Map<string, string>;
  onChange: Function;
  canEdit: boolean;
  userRole: UserRole;
}

export const FieldDefinition = (props: FieldDefinitionProps ) => {
  const visibleFields = props.field && props.field.fieldFragments.filter((item) => {
    const fieldFragmentVisibility = props.fieldFragmentVisibility.find(fieldFragmentVisibility => fieldFragmentVisibility.fieldFragment.id === item.id);
    const  fieldFragmentVisibilityValue = fieldFragmentVisibility ? fieldFragmentVisibility.visibility : 'inherit';
    if (fieldFragmentVisibilityValue === 'inherit' && item.visibility === 'visible-by-default') {
      return true;
    }
    if (fieldFragmentVisibilityValue === 'visible') {
      return true;
    }
    return false;
  });
  if (visibleFields.length === 0) {
    return null;
  }
  return (
    <div className="p-6 bg-white">
      <h2>{props.field.name.current}</h2>
      <div className="grid grid-cols-2 gap-2">
        {visibleFields.map((item) => <FieldFragment key={item.id} fieldFragment={item} {...props} /> )}
      </div>
    </div>
  );
};

