import { Trans } from 'react-i18next';
import { Tag as Tagd } from 'antd';
import React from 'react';

interface Props {
  value?: any;
  name: string;
  onClose: Function;
}

export const Tag: React.FC<Props> = (props:Props) => (
  <Tagd className='p-2' color="#484343" closable onClose={(evt: React.MouseEvent<HTMLElement>) => { props.onClose(props.value, evt); }}>
    <Trans i18nKey={props.name} />
  </Tagd>
);

