export const fieldFragmentOperations = [
  'add-to-total' ,
  'subtract-from-total',
  'ignore-total',
  // 'daily-total',
] as Array<FieldFragmentOperation>;

export const fieldUserRoles = [
  'USER',
  'ADMIN',
] as Array<UserRole>;

