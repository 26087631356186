import {request} from '../../api';
import config from '../../config';

function storageSet(key:string, value:string) {
  window.localStorage.setItem(key, value);
}

function storageGet(key:string) {
  return window.localStorage.getItem(key);
}

function storageRemove(key:string, value?:string) {
  if (value && window.localStorage.getItem(key) !== value) {
    return;
  }
  return window.localStorage.removeItem(key);
}

export function getAuthHeader() {
  const accessToken = storageGet('accessToken');
  if (!accessToken) { return {} };
  return {
    'Authorization': `Bearer ${accessToken}`,
  }
}

const baseUrl = `${config.baseUrl}/authentication`;
const service = () => {
  return {
    login: (userCredentials: UserCredentials) => request(`${baseUrl}/login`, 'post', userCredentials),
    logout: () => request(`${baseUrl}/logout`, 'post'),
    profiles: () => request(`${baseUrl}/profiles`, 'get'),
    me: () => request(`${baseUrl}/me`, 'get'),
    ping: () => request(`${baseUrl}/me`, 'get'),
    setAccessToken: (accessToken:string) => storageSet('accessToken', accessToken),
    getAccessToken: () => storageGet('accessToken'),
    removeAccessToken: (accessToken?:string) => storageRemove('accessToken', accessToken),
    updateUserPassword: (id: string, payload:UpdateUserCredentials) => request(`${baseUrl}/user/${id}/password`, 'patch', payload),
    updateUserProfile: (userProfile:UserProfile) => request(`${baseUrl}/me`, 'patch', userProfile),
  };
};



export default service;
