import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { findAll } from './actions';
import {selectNamedFields} from './namedFieldsSlice';


export const FetchAllNamedFieldsEffect = (): void => {
  const namedFields = useAppSelector(selectNamedFields);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if ( namedFields === null) {
      dispatch(findAll());
    }
  }, [dispatch, namedFields]);
};

